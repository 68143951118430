const th = {
  a: {
    allDP: 'DP ทั้งหมด',
    accountVehicleListHistory: 'ประวัติรายการใช้รถ',
    appoveToUseVehicle: 'อนุมัติการขอใช้งานรถ',
    accountVehicle2: 'Truck sharing board',
    accountVehicle: 'รถบัญชี',
    affiliatedFactories: 'โรงงานที่สังกัด',
    agencyCode: 'รหัสหน่วยงาน',
    agencyName: 'ชื่อหน่วยงาน',
    agencyType: 'ประเภทหน่วยงาน',
    autoMoveSite: 'ย้ายจุดอัตโนมัติ',
    averageKMperLitre: 'ค่าเฉลี่ย(กม./ลิตร)',
    averageKMperHours: 'ค่าเฉลี่ย(ลิตร/ชม.)',
    abnormal: 'ผิดปกติ',
    areaSiteIsNotGeofence: 'พื้นที่หน่วยงานยังไม่เป็น Geofence',
    arriveIn: 'อีก ',
    addDetail: 'เพิ่มรายละเอียด',
    addLocation: 'เพิ่มสถานที่',
    allBusiness: 'กิจการทั้งหมด',
    affiliateUnPlaces: 'สถานที่ที่ไม่มีสังกัด',
    affiliatePlaces: 'สถานที่ที่มีสังกัด',
    affiliateType: 'ประเภทสังกัด',
    allTruck: 'รถทั้งหมด',
    AppropriateImageFileSizeNoMoreThan512KB:
      'ขนาดไฟล์รูปที่เหมาะสม ไม่เกิน 512KB',
    alert_type: 'ประเภทแจ้ง Alert',
    accidentScene: 'จุดเกิดเหตุ',
    avgPerDistanceTravel: 'จำนวนครั้งเฉลี่ยต่อจำนวนระยะทางที่วิ่ง',
    avgPerTrip: 'จำนวนครั้งเฉลี่ยต่อจำนวนทริป',
    average_distance: 'ระยะทางเฉลี่ย (กิโลเมตร) (คำนวณสิ้นวัน)',
    amount_poured: 'ปริมาณที่เท (คิว)',
    actual_quantity: 'ปริมาณจริง (ลิตร)',
    amount_money: 'จำนวนเงินรวม',
    amountOilUsed: 'ปริมาณน้ำมันที่ใช้ (ลิตร)',
    averageFleet: 'ค่าเฉลี่ยทั้งฟลีท',
    allData: 'ข้อมูลทั้งหมด',
    approveYes: 'อนุมัติ',
    approveBy: 'อนุมัติ โดย',
    approveAt: 'อนุมัติเมื่อ',
    acceptancePoint: 'จุดรับงาน',
    accessory: 'อุปกรณ์เสริม',
    account: 'บัญชีผู้ใช้',
    accountName: 'ผู้ใช้งาน',
    ACCUMULATEEARNINGSTATISTICS: 'ACCUMULATE EARNING STATISTICS',
    action: 'ดำเนินการ',
    actionBy: 'ดำเนินการ โดย',
    actionRequired: 'แจ้งเตือนพร้อมเปิดเคส',
    actionDataTime: 'วันที่ เวลา',
    actionTime: 'เวลา',
    active: 'Active',
    activeStatusImage: 'รูปภาพแสดงสถานะกำลังใช้งาน',
    actualArrivalTime: 'เวลาที่ไปถึงจริง',
    actualReleaseTime: 'เวลาที่ออกจริง',
    actualTransitDistance: 'ระยะทางที่ขนส่งจริง',
    add: 'เพิ่ม',
    addARow: 'เพิ่มเเถว',
    addDrivinglicense: 'ใบอนุญาตขับขี่อื่นๆ',
    addFromRouteMaster: 'เพิ่มจากแผนการจัดส่ง',
    addInformation: 'เพิ่มข้อมูล',
    addItemInList: 'เพิ่มข้อมูลในรายการ',
    additionalDriverInformation: 'ข้อมูลพนักงานขับขี่เพิ่มเติม',
    additionalInformation: 'ข้มูลเพิ่มเติม',
    additionalVehicleInformation: 'ข้อมูลพาหนะเพิ่มเติม',
    address: 'ที่อยู่',
    addVehicleGroup: 'เพิ่มกลุ่มพาหนะ',
    adjustTheTable: 'ปรับตาราง',
    administrator: 'เห็นข้อมูลแบบผู้ดูแลระบบ',
    affiliation: 'ต้นสังกัด',
    african: 'แอฟริกัน',
    after1day: 'ผ่านไป 1 วัน',
    after1hour: 'ผ่านไป 1 ชั่วโมง',
    after30min: 'ผ่านไป 30 นาที',
    after4hour: 'ผ่านไป 4 ชั่วโมง',
    after5min: 'ผ่านไป 5 นาที',
    after8hour: 'ผ่านไป 8 ชั่วโมง',
    agency: 'เอเจนซี่',
    agencyNumber: 'เลขที่หน่วยงาน',
    aggressiveAccelerationAndImmediatelyBreak: 'ออกตัวกระชาก/เบรคกระทันหัน',
    aggressiveTurnWithoutLight: 'เลี้ยวกระทันหัน',
    aggressiveTurnChangeDirectionBeyondTheSpeedOfTheSpecifiedArea:
      'เลี้ยวกระทันหัน เปลี่ยนทิศทางเกินความเร็วตามพื้นที่ที่กำหนด',
    alarm: 'Alarm',
    alarmAndAlertReportClassifiedByDailyCarRegistration:
      'Alert และ Alarm แยกตามทะเบียนรายวัน',
    alarmAndAlertReportClassifiedByYearlyCarRegistration:
      'Alert และ Alarm แยกตามทะเบียน รายปี',
    alert: 'Alert',
    alert_setting_filter: 'กรุณาตั้งค่ารายงาน',
    alertAlarm: 'การแจ้งเตือนการฝ่าฝืน',
    alertNotiOnly: 'แจ้งเตือนอย่างเดียว',
    aliasTableCompany: 'ชื่อย่อ',
    all: 'ทั้งหมด',
    allowBOOMInside: 'อนุญาตให้เปิด BOOM ในสถานที่เท่านั้น',
    allowBOOMOutside: 'อนุญาตให้เปิด BOOM นอกสถานที่เท่านั้น',
    allowParkingInside: 'อนุญาตให้จอดในสถานที่เท่านั้น',
    allowParkingOutside: 'อนุญาตให้จอดนอกสถานที่เท่านั้น',
    allowPTODoorInside: 'อนุญาตให้เปิด PTO/ประตู ในสถานที่เท่านั้น',
    allowPTODoorOutside: 'อนุญาตให้เปิด PTO/ประตู นอกสถานที่เท่านั้น',
    allowSharedPartnerEdit: 'ผู้ว่าจ้างสามารถแก้ไข',
    allowWorkingTime: 'ช่วงเวลาที่อนุญาติทำงาน',
    allStatusDashCameras: 'ทั้งหมด',
    allItems: 'รายการทั้งหมด',
    amount: 'ครั้ง',
    amountPlant: 'จำนวนแพลนต์ใกล้เคียง',
    amountPlantIsRequired: 'กรุณากรอกจำนวนแพลนต์ใกล้เคียง',
    amphur: 'เขต/อำเภอ',
    and: 'เเละ',
    announcement: 'การประกาศ',
    announcementBroadcast: 'กระจายข่าว / เผยแพร่ข่าว ...',
    apply: 'ปรับใช้',
    appointment_date: 'วัน-เวลานัดหมาย',
    appointment_place: 'สถานที่นัด',
    appointmentLocation: 'สถานที่นัดหมาย',
    arab: 'อาหรับ',
    arriveArriveOnSite: 'ถึงหน้างาน',
    arriveAtDestination: 'ถึงปลายทาง',
    arriveAtfactory: 'ถึงโรงงาน',
    arriveAtTheOrigin: 'ถึงต้นทาง',
    assignAt: 'กำหนดเมื่อ',
    assignFirmware: 'กำหนดเฟิร์มแวร์',
    assignment: 'งานที่มอบหมาย',
    assignmentDate: 'วันที่มอบหมาย',
    assignTo: 'ผู้รับมอบหมาย',
    attachment_type: 'ประเภทไฟล์',
    available: 'พร้อมใช้งาน',
    avgEarningJob: 'Avg. Earning/Job',
    actual: 'ตามจริง',
    alertAndAlarm: 'Alert เเละ Alarm',
    arrangeACar: 'จัดรถเเล้ว',
    autoDNSwitching: 'เปลี่ยนปลายทางอัตโนมัติ',
    afterSuckingLatex: 'หลังดูดน้ำยาง',
    anotherProblemType: 'ประเภทปัญหาอื่นๆ',
    allDp: 'ใบงานทั้งหมด',
    allowAccessToInformation: 'อนุญาต ให้ดึงข้อมูล',
    accessToInformationIsNotAllowed: 'ไม่อนุญาต ให้ดึงข้อมูล',
    aSystemThatCanRetrieveData: 'ระบบที่สามารถดึงข้อมูลได้',
    amountBaht: 'จำนวนเงิน(บาท)',
    attachFile: 'แนบไฟล์',
    allVehicles: 'รถทั้งหมด',
    assigned: 'จองรถ',
    amountOilUse: 'ปริมาณน้ำมันที่ใช้',
  },
  b: {
    brokenDP: 'DP ที่เสีย',
    bluenetAccessToInformation: 'ดึงข้อมูลจาก bluenet',
    bluenetNotAccessToInformation: 'ไม่ดึงข้อมูลจาก bluenet',
    bluenetTransportDistance: 'ระยะขนส่ง Bluenet',
    building_time: 'เวลาสร้าง',
    before_litre: 'ก่อนเติม (ลิตร)',
    businesspartner: 'คู่ธุรกิจ',
    boxnumber: 'หมายเลขกล่อง',
    bluenet_other: 'อื่นๆ',
    is_bluenet_car: 'จาก Bluenet',
    back: 'กลับ',
    baht_defult: 'บาท',
    baht: '(บาท)',
    baht_km: '(บาท/กิโลเมตร)',
    bahtPerkm: '(บาท/กม.)',
    baht_trip: '(บาท/เที่ยว)',
    baht_ton_km: '(บาท/ตัน/กม.)',
    baht_unit_km: '(บาท/หน่วย/กม.)',
    baht_litre_km: '(บาท/ลิตร/กม.)',
    baht_litre: '(บาท/ลิตร)',
    base: 'พื้นฐาน',
    baseTemplate: 'Template พื้นฐาน',
    battery: 'แบตเตอรี่',
    battery_is_charge: 'ชาร์จอยู่',
    battery_not_charge: 'ไม่ได้ชาร์จอยู่',
    battery_status: 'สถานะการชาร์จแบต',
    behavior: 'พฤติกรรม',
    behavior_report_menu: 'พฤติกรรม',
    BehaviordrivingbycompanyReport:
      'สรุปพฤติกรรมการขับรถของ เจ้าหน้าที่บริการและจัดส่ง แต่ละผู้รับเหมา',
    BehaviordrivingReport: 'สรุปพฤติกรรมการขับรถของ เจ้าหน้าที่บริการและจัดส่ง',
    boatDeliveryDashboard: 'ระบบติดตามสถานะเรือ',
    boatDeliveryReport: 'การเดินทางเรือ',
    body_email: 'ข้อความเพิ่มเติม',
    bodyNo: 'หมายเลขตัวถังพาหนะ',
    bodyNoOfInstall: 'หมายเลขตัวรถที่ติดตั้ง',
    bookedDate: 'วันที่จอง',
    booking: 'งานจอง',
    bookingDate: 'วันที่จอง',
    bookingOverview: 'ภาพรวมงานจอง',
    bookingPool: 'งานจอง',
    bookingRemark: 'Booking Remark',
    BOOKINGSTATISTICS: 'สถิติการจอง',
    BOOM: 'BOOM',
    boxID: 'รหัสกล่อง',
    boxNumber: 'เบอร์กล่อง',
    brand: 'ยี่ห้อ',
    broken: 'เสีย',
    busStop1: 'Bus Stop 1',
    busStop2: 'Bus Stop 2',
    busStopPoint: 'Bus Stop Point',
    button: 'ปุ่ม',
    business: 'กิจการ',
    bookAnAppointmentWithCDAS: 'จองคิวกับ CDAS',
    bookAnAppointment: 'จองคิว',
    behindTheCar: 'ท้ายกระบะ',
    besideTheCar: 'ข้างตัวรถ',
    beforeSuckingTheLatex: 'ก่อนดูดน้ำยาง',
    business_group: 'กลุ่มธุรกิจ',
    bookingDate: 'วันที่งานจอง',
    bookingNo: 'เลขที่ใบงาน',
    bookingContactName: 'ชื่อ-นามสกุลผู้จอง/บริษัท',
    bookingContactNameIsRequired: 'กรุณาระบุ ชื่อ-นามสกุลผู้จอง/บริษัท',
    bookingContactPhone: 'เบอร์โทรติดต่อผู้จอง',
    bookingContactPhoneIsRequired: 'กรุณาระบุ เบอร์โทรติดต่อผู้จอง',
    bookAcar: 'จองรถ',
    bookingDetails: 'รายละเอียดการจอง',
    bigTruckRoute: 'เส้นทางรถโม่ใหญ่',
  },
  c: {
    carType: 'ประเภทรถ',
    chooseAll: 'เลือกทั้งหมด',
    coordinatorPhoneNumber: 'เบอร์โทรผู้ประสานงาน',
    chauffeurphoneNumber: 'เบอร์โทรคนขับ',
    contractorVehicleManagement: 'การจัดการรถของผู้รับเหมา',
    contractorNames: 'ชื่อผู้รับเหมา',
    countUnload: 'เทปูนครั้งที่',
    copyToken: 'คัดลอกโทเค็น',
    cancelRouting: 'ยกเลิกการนำทาง',
    cancelByUser: 'ยกเลิกจากผู้ใช้',
    cancelBySystem: 'ยกเลิกจากระบบ',
    chooseYourRoute: 'เลือกเส้นทางของท่าน',
    chooseRouteType: 'เลือกประเภทเส้นทาง',
    commentList: 'รายการความคิดเห็น',
    comment_text: 'กรอกรายละเอียดเพิ่มเติม',
    commentVehicleList: 'กรอกรายละเอียด',
    carParkedOutsideTheDesignatedArea: 'รถจอดนอกพื้นที่ที่กำหนด',
    concreteMixerFinished: 'คอนกรีตผสมเสร็จ',
    countIncorrectAvg: 'จำนวนครั้งการทำผิดเฉลี่ย/วัน',
    countEvent3: 'ขับรถความเร็วเกิน (พื้นที่ทั่วไป) 60 กม./ชม.',
    countEvent4: 'ขับรถความเร็วเกิน (พื้นที่ทั่วไป) 45 กม./ชม.',
    countEvent5:
      'ขับรถความเร็วเกิน โค้งหักศอก โค้งอันตราย โค้งที่เกิดอุบัติเหตุบ่อยครั้ง 20 กม./ชม.',
    countEvent6: 'ขับรถความเร็วเกิน (พื้นที่ทั่วไป) 30 กม./ชม.',
    countEvent7: 'ขับรถความเร็วเกิน (พื้นที่ทั่วไป) 10 กม./ชม.',
    companyName: 'สังกัดผู้ร่วมธุรกิจรถโม่',
    codejbs: 'รหัสจบส.',
    closeTimeFormat: 'ดับเครื่องยนต์ (วัน-เวลา)',
    count_spee_over_policy: 'ความเร็วเกินนโยบาย (ครั้ง)',
    cement_time: 'ระยะเวลาเทปูน (ชั่วโมง)',
    contractors: 'บริษัทผู้รับเหมา',
    concreteOutsideSpecifiedPoint: 'เทปูนนอกจุดที่กำหนด',
    concreteOutsideSpecifiedPointDetail: 'กรณีมีการเทปูนนอกจุดที่กำหนด',
    concretePointOverlap: 'เทปูนที่จุดคาบเกี่ยว',
    concretePointOverlapDetail:
      'กรณีที่รถเข้าถูกจุดแต่พื้นที่ของจุดนั้นทับซ้อนกับหน่วยงานอื่น',
    chooseList: 'กรุณาเลือกรายการ',
    cements: 'เทปูน',
    calculatedeliveryArea: 'คำนวนพื้นที่จัดส่ง',
    createaNewTicket: 'สร้างตั๋วใหม่',
    canDeliverOutsideTheArea: 'ส่งนอกพื้นที่ได้',
    canChangeDestination: 'เปลี่ยนปลายทางได้',
    cmd: 'cmd',
    chooseReportType: 'กรุณาเลือกประเภทรายงาน',
    chooseDriverCodePls: 'กรุณาเลือกรหัสคนขับ',
    chooseDriverNamePls: 'กรุณาเลือกชื่อคนขับ',
    chooseBusinessPls: 'กรุณาเลือกกิจการ',
    chooseSitePls: 'กรุณาเลือกหน่วยงาน',
    chooseDatePls: 'กรุณาเลือกวันที่',
    chooseDepartmentPls: 'กรุณาเลือกแผนก',
    choosePlantPls: 'กรุณาเลือกโรงงาน',
    chooseSectionPls: 'กรุณาเลือกฝ่าย/ส่วน',
    chooseRadius: 'กรุณาเลือกโรงงานที่รถจอดภายในรัศมี (กม.)',
    chooseSitePls: 'กรุณาเลือกกลุ่มไซต์งาน',
    chooseBusiness: 'เลือกกิจการ',
    chooseDepartment: 'เลือกแผนก',
    chooseSection: 'เลือกฝ่าย/ส่วน',
    chooseSite: 'เลือกกลุ่มไซต์งาน',
    confirmToCloseThisItem: 'ยืนยัน การปิดรายการนี้',
    confirmToChangeStatus: 'ยืนยัน การเปลี่ยนสถานะใบงานแจ้งซ่อม',
    callQueue: 'เรียกคิว',
    camera_back: 'กล้องในรถ',
    camera_extra: 'กล้องเสริม',
    camera_position: 'ตำแหน่งกล้อง',
    cancel: 'ยกเลิก',
    cancelAt: 'ยกเลิกเมื่อ',
    cancelled: 'ยกเลิก',
    cancelReason: 'เหตุผลการยกเลิก',
    carNotOnline: 'รถไม่ออนไลน์',
    carService: 'ศูนย์บริการ',
    carTax: 'ภาษีรถยนต์',
    category: 'หมวด',
    cc_email: 'สำเนา',
    certificateNumber: 'หมายเลข คำร้องขอใบรับรอง',
    certificateRequestDate: 'วันที่ขอใบรับรอง',
    changeLanguage: 'เปลี่ยนภาษา',
    changePassword: 'เปลี่ยนรหัสผ่าน',
    changeProduct: 'เปลี่ยนสินค้า',
    channel: 'ช่องทาง',
    chasisSerialNumber: 'หมายเลขตัวถัง',
    chauffeur: 'พนักงานขับขี่',
    chauffeurData: 'ข้อมูลคนขับ',
    chineseKoreanJapanese: 'จีน/ กาหลี/ญี่ปุ่น',
    clear: 'ล้าง',
    clearStatus: 'คืนสถานะ',
    clearUp: 'ล้างค่า',
    clearSearch: 'ล้างค่าการค้นหา',
    close: 'ปิด',
    closed: 'ปิด',
    closeFixed: 'เรียบร้อย-ปิดตั๋ว',
    closeNotAvailable: 'รถไม่พร้อม-ปิดตั๋ว',
    cmd: 'CMD',
    collapse: 'ย่อ',
    collapseExpand: 'ย่อ/ขยาย',
    Color: 'สี',
    comment: 'รายละเอียด',
    communityArea: 'เขตชุมชน',
    company: 'บริษัท',
    company_partner: 'บริษัทผู้ร่วมกิจการ',
    companyBilling: 'บริษัทที่เรียกเก็บเงิน',
    companyCode: 'รหัสบริษัท',
    companyPartner: 'ผู้รับเหมา',
    CompareVehicleSccoReport: 'รายงาน เปรียบเทียบพาหนะ SCCO',
    complete: 'เสร็จเรียบร้อย',
    completeIsupply: 'จัดส่งสำเร็จ',
    complete_date: 'วันที่เสร็จ',
    compulsory: 'ประกันภัยรถยนต์ภาคบังคับ (พ.ร.บ.)',
    concreteDeliveryDashboard: 'การจัดส่งคอนกรีต',
    concreteDeliveryManagerDashboard: 'การจัดส่งคอนกรีต',
    concretePlant: 'โรงงานคอนกรีต',
    configuration: 'ปรับค่า',
    confirm: 'ยืนยัน',
    confirm_appointment_place: 'อาจมีการเปลี่ยนแปลงจังหวัด และอำเภอ',
    confirm_type: 'ยืนยันการส่งโดย',
    confirmNewPassword: 'ยืนยันรหัสผ่านใหม่',
    confirmPassword: 'ยืนยันรหัสผ่าน',
    confirmSendingBy: 'ยืนยันการส่งโดย',
    confirmToAddThisItem: 'ยืนยัน ต้องการเพิ่มรายการนี้',
    confirmToCancelThisItem: 'ยืนยัน ต้องการยกเลิกรายการนี้',
    confirmToCloseStatus: 'ยืนยัน รายการนี้ซ่อมเสร็จเรียบร้อยแล้ว',
    confirmToDeleteThisItem: 'ยืนยัน ต้องการลบรายการนี้',
    confirmToEditThisItem: 'ยืนยัน ต้องการเเก้ไขรายการนี้',
    confirmToReceiveStatus: 'ยืนยัน ต้องการรับเรื่องรายการนี้',
    confirmToSendTheseItems: 'ยืนยัน ต้องการส่งรายการเหล่านี้',
    connection: 'เชื่อมต่อสัญญาณ',
    connectionLost: 'ขาดการติดต่อ',
    consignee: 'ผู้รับสินค้า',
    consigneePhone: 'เบอร์โทรศัพท์ผู้รับสินค้า',
    contact: 'ติดต่อ',
    contact_name: 'ชื่อบุคคลติดต่อ',
    contact_telno: 'เบอร์ติดต่อ',
    Contactmanagement: 'เบอร์โทรศัพท์พาหนะ',
    contactName: 'ชื่อบุคคลติดต่อ',
    contactSupportForUserThisMenu:
      'เนื่องจากมีข้อมูลสถานที่เป็นจำนวนมาก กรุณาติดต่อซัพพอร์ทเพื่อใช้งานเมนูนี้',
    contactTelno: 'เบอร์ติดต่อ',
    continuationOfEvents: 'ความต่อเนื่องของเหตุการณ์ (นาที)',
    continuousDrive: 'ทำงานต่อเนื่องได้ไม่เกิน',
    continuousDriveInADay: 'ทำงานต่อเนื่องในหนึ่งวันได้ไม่เกิน',
    continuousDriving: 'ขับรถต่อเนื่อง',
    continuousDrivingLongTime: 'ขับรถต่อเนื่องนาน (ครั้ง)',
    continuousDrivingLongTimeDate: 'ขับรถต่อเนื่องนาน (เวลา)',
    contractor: 'ผู้รับจ้าง',
    contractorName: 'ชื่อ ผรม.',
    contractShippingRate: 'อัตราค่าขนส่งตามสัญญา',
    coordinates: 'พิกัด',
    coordinatesGPS: 'พิกัด GPS',
    copyLink: 'คัดลอกลิงก์',
    couldNotDisplayDirections: 'ไม่สามารถแสดงเส้นทางได้เนื่องจาก: ไม่พบผลลัพธ์',
    courseName: 'ชื่อหลักสูตร',
    cpacPolicy: 'นโยบายความปลอดภัย',
    create_trip: 'สร้างทริป',
    created_by: 'สร้างโดย',
    createdAt: 'สร้างเมื่อ',
    createdBy: 'สร้างโดย',
    createFrimware: 'สร้างเฟิร์มเเวร์',
    creator: 'ผู้สร้าง',
    criteria: 'เงื่อนไข',
    criterionAndDeductMarksForTripSuccess:
      'เกณฑ์และการหักคะแนน ด้านความสำเร็จทริป',
    criticality: 'ภาวะวิกฤต',
    criticalLevel: 'ระดับความรุนแรง',
    crossroad: 'ในทางเเยก',
    crushingPlant: 'ต้นทาง',
    currentAddress: 'ที่อยู่ปัจจุบัน',
    currentLocation: 'ตำเเหน่งปัจจุปัน',
    currentQueue: 'คิวปัจจุบัน',
    currentVehicle: 'พาหนะปัจจุบัน',
    custom: 'ปรับแต่ง',
    customer: 'ลูกค้า',
    customerDetail: 'รายละเอียดลูกค้า',
    customerID: 'Customer ID',
    customerName: 'ชื่อลูกค้า',
    customerType: 'ประเภทลูกค้า',
    cycleOfDrumRotation: ' จำนวนรอบการหมุนของโม่',
    concreteTrip: 'การส่งคอนกรีต',
    confirmed: 'สำเร็จ',
    cement: 'ซีเมนต์',
    changeDestination: 'เปลี่ยนปลายทาง',
    changeHistoryOfData: 'ประวัติการเปลี่ยนเเปลงข้อมูล',
    changeHistoryOfTheLatestInformation:
      'ประวัติการเปลี่ยนแปลงของข้อมูล ล่าสุด',
    changeHistoryOfTheAllInformation: 'ประวัติการเปลี่ยนแปลงของข้อมูล ทั้งหมด',
    changeHistoryOfPreviousInformation:
      'ประวัติการเปลี่ยนแปลงของข้อมูล ก่อนปัจจุบัน',
    clearTicketStatus: 'เคลียร์สถานะตั๋ว',
    contact_phone: 'เบอร์ติดต่อ/เบอร์พนักงานขับขี่',
    contact_phone_require: 'กรุณากรอกเบอร์ติดต่อ/เบอร์พนักงานขับขี่',
    contact_phone_length_max:
      'กรุณากรอกเบอร์ติดต่อ/เบอร์พนักงานขับขี่ต้องมีความยาวไม่เกิน 15 ตัวอักษร',
    contact_phone_length_min:
      'กรุณากรอกเบอร์ติดต่อ/เบอร์พนักงานขับขี่ต้องมีความยาวไม่ต่ำกว่า 8 ตัวอักษร',
    contact_phone_invalid: 'กรุณากรอกเบอร์ติดต่อ/เบอร์พนักงานขับขี่ให้ถูกต้อง',
    contact_name_require: 'กรุณากรอกชื่อบุคคลติดต่อ',
    contract: 'สัญญา',
    csv: 'CSV',
    changeOriginLocation: 'เปลี่ยนต้นทาง',
    changeDestinationLocation: 'เปลี่ยนสถานที่ปลายทาง',
    contractNumber: 'เลขที่สัญญา',
    contractValidityPeriod: 'ระยะเวลาที่สามารถใช้งานสัญญาได้',
    conditionsForAcceptingWork: 'เงื่อนไขการรับงาน',
    close: 'ปิด',
    conditionsForSubmittingWork: 'เงื่อนไขการส่งงาน',
    changeDestinationByDriver: 'เปลี่ยนปลายทางโดยพนักงานขับขี่',
    condition: 'เงื่อนไข',
    cancelALocationFromAnotherSystem: 'ยกเลิกสถานที่จากระบบอื่น',
    codeComparativeRawMaterials: 'รหัส วัตถุดิบเทียบ',
    closeWork: 'ปิดงาน',
    cdas: 'CDAS',
    comparativeRawMaterials: 'วัตถุดิบเทียบ',
    comparativeRawMaterialsRequired: 'ระบุ วัตถุดิบเทียบ',
    countPlantMiningContract: 'จำนวนสัญญาขนส่งวัตถุดิบ',
    countVehicle: 'จำนวนพาหนะ',
    contractStartDate: 'วันที่เริ่มต้นใช้สัญญา',
    contractExpirationDate: 'วันที่สิ้นสุดใช้สัญญา',
    cer: 'ใบ Cer.',
    closeBy: 'ปิดงานโดย',
    confirmWantToCloseThisItem: 'ยืนยัน ต้องการปิดงานรายการนี้',
    cannotFix: 'ไม่สามารถเเก้ไขได้',
    code: 'รหัส',
    contractPeriod: 'ระยะเวลาสัญญา',
    contractStatus: 'สถานะสัญญา',
    contractActive: 'คงอยู่',
    contractInactive: 'หมดอายุ',
    cardNumber: 'เลขบัตร',
    cardNoIsRequired: 'ระบุ card no.',
    createBookingOrder: 'สร้างงานจอง',
    confirmationOfBooking: 'ยืนยันการจอง',
    currentJobStatus: 'สถานะงานปัจจุบัน',
    chooseTheDateYouWantToSend: 'เลือกวันที่ต้องการส่ง',
    cancelBooking: 'ยกเลิกการจอง',
    customTime: 'กำหนดเอง',
    countAll: 'จำนวนตั๋วทั้งหมด',
    countActive: 'จำนวนตั๋วที่คิดระยะทาง',
  },
  d: {
    devicePhoneNumber: 'เบอร์โทรอุปกรณ์',
    destinationTo: 'ปลายทาง',
    dataOverlapOccurs: 'เกิดการทับซ้อนของข้อมูล',
    desiredFactory: 'โรงงานที่ต้องการ',
    desiredDateRange: 'ช่วงวันที่ต้องการ',
    desiredTime: 'ช่วงวันที่ต้องการ',
    dateForDP: 'วันที่พิมพ์ DP',
    distanceOldNew: 'ระยะห่างระหว่างหน่วยงานเก่าและใหม่(เมตร)',
    dateFuelCost: 'ค่าน้ำมันวันที่',
    dpNotCounting: 'ไม่นับ DP',
    dpCounting: 'นับ DP',
    direction: 'นำทาง',
    drawSmallTruckRoute: 'วาดเส้นทางโม่เล็ก',
    drawBigTruckRoute: 'วาดเส้นทางโม่ใหญ่',
    drawSmallTruckRouteDoNotEnter: 'วาดเส้นทางรถโม่เล็กห้ามเข้า',
    detail: 'รายละเอียด',
    destinationPoint: 'จุดจอดปลายทาง',
    dpNumber: 'หมายเลข DP',
    drivingTime: 'เวลาขับ',
    distance_speed_over_policy: 'ความเร็วเกินนโยบาย (กม.)',
    duration_speed_over_policy: 'ความเร็วเกินนโยบาย (นาที)',
    diffTime: 'ระยะเวลา (ชั่วโมง:นาที)',
    distanceOnSite: 'ระยะทางในไซต์',
    distanceDifferenceKm: 'ผลต่างระยะทาง (กม.) User/GPS',
    distance_return_plant: 'ระยะทางขากลับแพลนต์ (กิโลเมตร) (คำนวณสิ้นวัน)',
    distance_from_plant_to_Site:
      'ระยะทางจากแพลนต์ถึงไซต์งาน (กิโลเมตร) (คำนวณสิ้นวัน)',
    day_in_month: 'จำนวนวันทำงานทั้งหมด',
    document_number: 'เลขที่เอกสารแจ้งซ่อม',
    diff_date: 'รวมเวลาแก้ไข (วัน:ชั่วโมง)',
    disconnected_total: 'รวมเวลาขาดการติดต่อ (นาที)',
    disconnected_begin: 'เวลาเริ่มขาดการติดต่อ',
    disconnected_end: 'เวลาสิ้นสุดการขาดการติดต่อ',
    durationDisconnected: 'ระยะเวลาที่ขาดการติดต่อ',
    dpReleaseDate: 'วันที่ออกดีพี',
    downloadTemplate: 'โหลดเทมเพลต',
    drivingSafety: 'ความปลอดภัยในการขับขี่',
    drivingDetail: 'รายละเอียดการขับขี่',
    deleteRoute: 'ลบเส้นทาง',
    departure: 'ออกจากแพลนต์',
    datetimeloadingcement: 'วัน/เวลาที่โหลดปูน',
    dashboarddelivery: 'แดชบอร์ดการจัดส่ง',
    defaultfilter: 'ค่ากรองตั้งต้น',
    department: 'ฝ่ายส่วน',
    department_code: 'รหัสฝ่ายส่วน',
    department_truckincorrectpoint: 'เเผนก',
    departmentPart: 'ฝ่าย/ส่วน',
    division: 'กิจการ',
    division_code: 'รหัสกิจการ',
    distancereport: 'ระยะทางการจัดส่งคอนกรีต',
    details: 'รายละเอียด',
    drivinglog: 'บันทึกการขับขี่',
    distanceByPoint: 'ระยะทางจุดต่อจุด',
    driverKpiDashboard: 'สรุปการใช้พาหนะ',
    driverName: 'ชื่อคนขับ',
    driver_name: 'คนขับรถ',
    daily: 'รายวัน',
    DailyAlarmAndAlertReportByDriver:
      'Alert และ Alarm แยกตามพนักงานขับขี่รายวัน',
    dailyCarStatusReport: 'สรุปสถานะพาหนะรายวัน',
    dailyDrivingBehaviorReport: 'พฤติกรรมการขับขี่รายวัน',
    DailyiconsiamReport: 'การให้บริการเรือ / รถ แบบรายวัน',
    DailyReport: 'สรุปการวิ่งประจำวัน',
    dangerous: 'อันตราย',
    dashboard: 'แดชบอร์ด',
    dashCamera: 'ระบบเฝ้าดูผ่านแดชแคม',
    data: 'ข้อมูล',
    dataForDLT: 'Data For DLT',
    dataSource: 'แหล่งที่มาของข้อมูล',
    dataType: 'ชนิดข้อมูล',
    dataVisibility: 'การมองเห็นข้อมูล',
    date: 'วันที่',
    dateIsRequired: 'กรุณาระบุ วันที่',
    date_entry: 'วันที่เข้า',
    date_out: 'วันที่ออก',
    date_time: 'วันที่ และเวลา',
    date_time_entry: 'วันที่-เวลาเข้า',
    date_time_out: 'วันที่-เวลาออก',
    dateEnd: 'วันที่สิ้นสุด',
    dateOfBirth: 'วันเกิด',
    dateofRawMaterialRequirement: 'วันที่ต้องการวัตถุดิบ',
    dateOfSubmission: 'วันที่ยื่นคำขอ',
    dateRange: 'ช่วงวันที่',
    dateRangeNotBeMoreThan31Days: 'ช่วงวันที่ที่เลือกไม่ควรมากกว่า 31 วัน',
    dateRangeNotBeMoreThan3Days: 'ช่วงวันที่ที่เลือกต้องไม่เกิน 3 วัน',
    dateStart: 'วันที่เริ่มต้น',
    dateSubmit: 'วันที่ส่งเอกสาร',
    dateTime: 'วัน/เวลา',
    day: 'วัน',
    daYouWantToReActive: 'คุณต้องการที่จะเปิดใช้งานอีกครั้งหรือไม่',
    daYouWantToReTicketStatus: 'คุณต้องการที่จะเคลียร์สถานะตั๋วหรือไม่',
    daysBeforeNotification: 'จำนวนวันก่อนแจ้งเตือน',
    dayTime: 'ช่วงเวลากลางวัน',
    ddHhMm: '(วัน:ชม:นาที)',
    deduct: 'หัก',
    default: 'ค่าเริ่มต้น',
    defaultImageBylocationType: 'รูปค่าเริ่มต้น ตามชนิดสถานที่',
    defaultLang: 'Default lang',
    defaultLatitude: 'Default latitude',
    defaultLongitude: 'Default longitude',
    delete: 'ลบ',
    deleteFail: 'ลบข้อมูลไม่สำเร็จ',
    destinationIsupply: 'จัดส่งที่ปลายทาง',
    deletedAt: 'ลบข้อมูลเมื่อ',
    deletedBy: 'ลบข้อมูลโคย',
    deleteInformation: 'ลบข้อมูล',
    deleteReason: 'เหตุผลการลบ',
    deliver: 'การจัดส่ง',
    deliver_duration: 'เวลาเริ่ม - เวลาสิ้นสุด',
    deliver_status: 'สถานะส่งของ',
    deliveryStatusIsupply: 'สถานะการจัดส่ง',
    Delivered: 'ส่งของแล้ว',
    deliverPoint: 'จุดส่งของ',
    delivery: 'จัดส่ง',
    deliveryAddress: 'ที่อยู่สำหรับการจัดส่ง',
    deliveryAvailable: 'กำลังเข้าโรงงาน',
    deliveryBillNo: 'เลขที่ใบส่งของ',
    deliveryContractWasNotFound: 'ไม่พบสัญญาการจัดส่งวัตถุดิบในระบบ',
    deliveryDashboard: 'การขนส่งวัตถุดิบ',
    deliveryDistance: 'วิ่งงาน',
    deliveryItem: 'การส่งอุปกรณ์',
    deliveryItemDeleteHistoryPool: 'ประวัติงานส่งอุปกรณ์ (EZY GPS)',
    deliveryItemPool: 'งานส่งอุปกรณ์ (EZY GPS)',
    deliveryOutsideTheArea: 'ส่งนอกพื้นที่',
    deliveryPoint: 'จุดส่งของ',
    deliveryReport: 'การขนส่งวัตถุดิบ',
    DeliveryReport: 'การส่งสินค้า',
    deliveryStatus: 'สถานะการจัดส่ง',
    deliverySuccess: 'ส่งเรียบร้อยแล้ว',
    deliveryTime: 'เวลาส่งของ',
    deliveryLocation: 'ตำเเหน่งส่งของ',
    discontinuedVehiclesShouldNotExceed: 'พาหนะที่เลิกไม่ควรเกิน ',
    deliverytrip: 'ทริปการส่งของ',
    descrip: 'รายละเอียด',
    description: 'รายละเอียด',
    destination: 'จุดหมาย',
    destinationDetail: 'รายละเอียดจุดหมาย',
    destinationLocation: 'สถานที่ปลายทาง',
    destinationLocation_duplicate_require: 'สถานที่ปลายทางนี้มีในทริปนี้แล้ว',
    destinationLocation_require: 'กรุณากรอกสถานที่ปลายทาง',
    destinationLocationcode: 'รหัสสถานที่ปลายทาง',
    detail: 'รายละเอียด',
    detail_problem: 'อาการของปัญหา(อื่นๆ โปรดระบุ)',
    detailOfConcreteTruck: 'รายละเอียดสำหรับรถบรรทุกคอนกรีต',
    detailproblem: 'วิธีการแก้ไข',
    detailProblemServiceMoveDevice: 'อาการของปัญหาที่ได้รับแจ้ง',
    device: 'อุปกรณ์',
    deviceName: 'ชื่ออุปกรณ์',
    devicePhone: 'เบอร์โทรกล่อง',
    deviceType: 'ประเภทอปกรณ์',
    diff: 'Diff',
    dischargeDenominator: 'ค่าคำนวณปริมาณในการเท',
    disconnected: 'ขาดการติดต่อ',
    displayTime: 'เวลาการเเสดงผล',
    distance: 'ระยะทาง',
    distance_less_than: 'ระยะทางน้อยกว่า',
    distance_more_than: 'ระยะทางที่ถือว่าทำงานต่อวัน (กม.)',
    distanceDPWithKm: 'ระยะทาง DP (กิโลเมตร)',
    distanceWithKm: 'ระยะทาง (กิโลเมตร)',
    DistfromlastmaintenanceReport: 'พาหนะที่มีระยะทางวิ่งสะสมล่าสุด',
    doc_no: 'เลขเอกสาร',
    document: 'เอกสาร',
    done: 'เสร็จสิ้น',
    doNotInstall: 'ยังไม่ติดตั้ง',
    doNotParkBesideTheRoad: 'ห้ามจอดไหล่ทาง',
    doNotSwipeCardsOrDamageCards: 'ไม่รูดบัตรหรือบัตรเสียหายหนัก',
    doNumber: 'Do Number',
    download: 'ดาวน์โหลด',
    downloadcertificate: 'ดาวน์โหลดใบรับรอง',
    downloadSampleFiles: 'ดาวน์โหลดไฟล์ตัวอย่าง',
    doYouNeedToRebootTheseItems: 'คุณต้องการรีบูตรายการเหล่านี้หรือไม่',
    doYouWantToAddTheseItems: 'คุณต้องการเพิ่มรายการเหล่านี้หรือไม่',
    doYouWantToCancel: 'คุณต้องการยกเลิกหรือไม่',
    doYouWantToConfirmSend: 'คุณต้องการยืนยันการส่งหรือไม่',
    doYouWantToConfirm: 'คุณต้องการยืนยันข้อมูล ?',
    doYouWantToDeleteTheseItems: 'คุณต้องการลบรายการเหล่านี้หรือไม่',
    doYouWantToEditTheseItems: 'คุณต้องการเเก้ไขรายการเหล่านี้หรือไม่',
    doYouWanttoReActive: 'คุณต้องการที่จะเปิดใช้งานอีกครั้งหรือไม่',
    doYouWantToSendAnEmailToUsersToVerifyTheirIdentity:
      'คุณต้องการส่งอีเมลไปยังผู้ใช้งานเพื่อยืนยันตัวตนหรือไม่',
    DozeoffriskReport: 'เสี่ยงหลับใน',
    draft: 'ดราฟต์',
    draw: 'วาด',
    driveContinuouslyForMoreThan10HoursDay: 'ปฏิบัติงาน > 10 ชม./วัน',
    driveContinuouslyForMoreThan4Hours: 'ขับต่อเนื่อง > 4 ชม.',
    driveContinuouslyForMoreThan4HourWithoutRestingFor30Minute:
      'ขับต่อเนื่องเกิน 4 ชั่วโมงโดยไม่พัก 30 นาที',
    driveMoreThanTheSpeedLimitInTheBend:
      'ขับรถเกินความเร็วที่กำหนดในทางโค้งทั่วไป',
    driveOverTheSpeedLimitForDangerousCurves:
      'ขับรถเกินความเร็วที่กำหนดในทางโค้งอันตราย',
    driveOverTheSpeedLimitInTheCrossroads: 'ขับขี่ความเร็วเกินกำหนด ในทางแยก',
    driver: 'พนักงานขับขี่',
    driverTracking: 'คนขับขี่',
    driver1: 'พนักงานขับขี่ 1',
    driver2: 'พนักงานขับขี่ 2',
    driverBlacklist: 'พนักงานห้ามเข้าพื้นที่',
    driverCode: 'รหัสพนักงานขับขี่',
    driverHistory: 'ประวัติพนักงานขับขี่',
    DriverinformationReport: 'ข้อมูลพนักงานขับขี่',
    driverNameJBS: 'ชื่อ-นามสกุลจบส.',
    driverName: 'ชื่อพนักงานขับขี่',
    driverPhoneNumber: 'เบอร์โทรพนักงานขับขี่',
    driveTooFastInTheCommunity: 'ขับเร็วเกินกำหนด ในเขตชุมชน',
    driveTooFastInTheNormalRoute: 'ขับขี่เร็วเกินกำหนด ในเส้นทางปกติ',
    driving: 'ขับพาหนะ',
    drivingLogDescription:
      'ค่าน้ำมันที่แสดงมาจากการตั้งค่าเบื้องต้นของระบบหากต้องการความแม่นยำรบกวนแจ้ง',
    drivingLogDescription1: '1.ความจุถัง',
    drivingLogDescription2: '2.วันที่เติมน้ำมันเต็มถัง',
    drivingLogDescription3:
      '3.จำนวนลิตรที่เติมกับผู้ให้บริการเพื่อทำการตั้งค่าน้ำมันใหม่',
    DrivingdistanceReport: 'สรุประยะทางการวิ่งประจำวัน',
    DrivinghourReport: 'พฤติกรรมการขับขี่',
    drivingIiceneType: 'ประเภทใบขับขี่',
    drivingIicenseNumber: 'หมายเลขใบขับขี่',
    drivinglicense: 'ใบอนุญาตขับขี่',
    drivingLicenseNumberMustBeNumber: 'หมายเลขใบขับขี่ต้องเป็นตัวเลข',
    DrivinglicensescanlogReport: 'การรูดใบขับขี่',
    DrivingNamelicensescanlogReport: 'ชื่อจากการรูดใบขับขี่',
    DrivingAddresslicensescanlogReport: 'สถานที่รูดใบขับขี่',
    DrivinglogReport: 'บันทึกการขับขี่',
    DrivinglogReport2: 'บันทึกการขับขี่ 2',
    DrivinglogspecialReport: 'บันทึกการขับขี่ (ใหม่)',
    drivingOver10HrsDay: 'ขับรถนานเกิน 10 ชม./วัน',
    drivingOver10Hrs: 'รถวิ่งเกิน 10ชม.',
    drivingOver4Hrs: 'ขับรถนานเกิน 4 ชม.',
    DrivingoverlimitReport: 'พาหนะวิ่งงานเกินเวลาที่กำหนด',
    drivingOverSpeedLimitInNormalRoute:
      'ขับรถเกินความเร็วที่กำหนดในเส้นทางทั่วไป',
    drivingOverSpeedLimitInUrbanArea: 'ขับรถเกินความเร็วที่กำหนดในเขตชุมชน',
    drivingPeriodRange: 'ช่วงระยะเวลาการขับขี่(นาที)',
    drivingTime: 'เวลาการขับขี่',
    drumCapacity: 'ความจุโม่',
    duration: 'ระยะเวลา',
    duration_time: 'ช่วงเวลาของข้อมูล',
    durationOfPTOGate1On: ' ระยะเวลาเปิด PTO/ประตู1',
    duringDelivery: 'ระหว่างการจัดส่ง',
    mdvrdownloadfile: 'ดาวน์โหลดวิดีโอย้อนหลัง MDVR',
    drivingSummary: 'ภาพรวมสรุปการขับขี่รายคัน',
    duration_repair: 'ระยะเวลาการแจ้งซ่อม',
    drumRpm: 'ความเร็วโม่',
    dataTimeGR: 'วันที่เวลา GR',
    dashboard: 'เเดชบอร์ด',
    doYouWantToChangeVehicleStatus: 'คุณต้องการจะเปลี่ยนสถานะพาหนะใช่หรือไม่?',
    driverPhone: 'เบอร์โทรศัพท์',
    dispatch_name: 'ชื่อ Dispatch',
    dispatch_code: 'รหัส Dispatch',
    dispatch_name_code: 'รหัส/ชื่อ Dispatch',
    duplicateRawMaterialCode: 'รหัสวัตถุดิบซ้ำกัน',
    deleteSuccess: 'ลบข้อมูลสำเร็จ',
    dontBookAnAppointment: 'ไม่ต้องจองคิว',
    deliveryBeyondTheSpecifiedTime: 'จัดส่งเกินเวลาที่กำหนด',
    destinationChangeStatus: 'สถานะการเปลี่ยนปลายทาง',
    deliveredInThePickupArea: 'ส่งในจุดรับสินค้าเท่านั้น',
    donArrangeACar: 'ไม่จัดรถ',
    duplicateRawMaterialAndSourceCode: 'รหัสวัตถุดิบและต้นทางซ้ำกัน',
    district: 'อำเภอ',
    doYouWantToChangeBookingOrderStatus:
      'คุณต้องการเปลี่ยนสถานะใบงานจองใช่หรือไม่?',
    doYouWantToCancelBookingOrder: 'คุณต้องการยกเลิกใบงานจองใช่หรือไม่?',
    destinationView: 'มุมมองปลายทาง',
    distance_from_site: 'ระยะระหว่างรถกับไซต์ (km) :',
    deviceCode: 'รหัสอุปกรณ์',
    driver_code: 'รหัสคนขับ',
    defaultSetting: 'ตั้งค่าเริ่มต้น',
    deletedPolygon: 'วาดครอบจุด GPS ที่ต้องการลบ',
    delivery_period_google: 'ระยะทางจาก Google (Bluenet)',
    delivery_period_manual: 'ระยะทางที่กรอก (Bluenet)',
    delivery_period_gps: 'ช่วงการจัดส่งจริง(GPS)',
    distance_code: 'ช่วงการจัดส่ง (Bluenet)',
  },
  dashboard_summary: {
    close: 'ดับเครื่อง',
    date: 'วันที่',
    distance: 'ระยะทางรวม',
    enginePanel: 'การใช้ประโยชน์รถ',
    fuelAvgPanel: 'อัตราการใช้น้ำมันเฉลี่ย',
    fuelAvgPanelUsage: 'อัตราการใช้น้ำมัน (กิโลเมตร/ลิตร)',
    fuelSummary: 'ปริมาณการใช้น้ำมันรวม',
    fuelToBath: 'คิดเป็นเงิน (B10)',
    fuelUse: 'น้ำมันใช้ไป',
    hours: 'ชม.',
    lite: 'ลิตร',
    lost: 'ไม่ส่งข้อมูล',
    maxSpeed: 'ความเร็วสูงสุด',
    minute: 'นาที',
    hour: 'ชั่วโมง',
    parking: 'จอดไม่ดับเครื่อง',
    parkingPanel: 'จอดไม่ดับเครื่องรวม',
    pick_date: 'กรุณาเลือกช่วงวัน',
    point: 'คะแนน',
    rangeAvg: 'ค่าเฉลี่ยของช่วงเวลาที่เลือก',
    safetyPanel: 'สรุปการขับขี่แบบปลอดภัย',
    savePanel: 'สรุปการขับขี่แบบประหยัด',
    speedOver: 'ความเร็วเกิน(รวม)',
    speedPanel: 'ความเร็วเกินค่าปลอดภัย',
    start: 'วิ่ง',
    title: 'สรุป ณ วันที่',
    to: 'ถึง',
    unit: 'คัน',
    ticket_status: 'สถานะตั๋ว',
  },
  e: {
    enterAccountToGenerateToken: 'ป้อนบัญชีเพื่อสร้างโทเค็น',
    editedBy: 'แก้ไขโดย',
    editTable: 'แก้ไขตาราง',
    exampleData: 'ตัวอย่างข้อมูล',
    exampleForData: 'แสดงตัวอย่างข้อมูล',
    editRoute: 'แก้ไขเส้นทาง',
    earning: 'Earning',
    earningOverview: 'ภาพรวมรายได้',
    EarningReceivedSTATISTICS: 'Earning/Received STATISTICS',
    earningType: 'Earning Type',
    edit: 'แก้ไข',
    editPassword: 'แก้ไขรหัสผ่าน',
    editProfile: 'แก้ไขโปรไฟล์',
    editStatus: 'สถานะ',
    efficiency_report_menu: 'ประสิทธิภาพ',
    email: 'อีเมล',
    email_invalid: 'อีเมลที่กรอกไม่ถูกต้อง',
    email_require: 'กรุณากรอกอีเมล',
    email_subject: 'หัวข้ออีเมล',
    email_subject_require: 'กรุณากรอกหัวข้ออีเมล',
    emailAE: 'อีเมล์ AE',
    emailIsInvalid: ' อีเมลไม่ถูกต้อง',
    emailVerification: 'การยืนยันอีเมล',
    emailVerificationStatus: 'สถานะการยืนยันอีเมล',
    emailVerificationSuccessful: 'ยืนยันอีเมลแล้ว',
    emergencyCase: 'กรณีฉุกเฉิน',
    emergencyStatus: 'สถานะฉุกเฉิน',
    employeeID: 'รหัสพนักงาน',
    employeeInformation: 'ข้อมูลพนักงาน',
    employee: 'พนักงาน',
    endAt: 'เวลาสิ้นสุด',
    endDate: 'วันสิ้นสุด',
    endDestinationCode: 'รหัสปลายทาง',
    endDestinationName: 'ชื่อปลายทาง',
    endLocationCode: 'รหัสปลายทาง',
    endLocationName: 'ชื่อปลายทาง',
    endValidDate: 'วันที่สิ้นสุดการใช้พาหนะ',
    energy_report_menu: 'พลังงาน',
    engine: 'เครื่องยนต์',
    engine_off_time_end_at: 'เวลาดับเครื่องสิ้นสุด',
    engine_off_time_start_at: 'เวลาดับเครื่องเริ่มต้น',
    engine_on_time_end_at: 'เวลาติดเครื่องสิ้นสุด',
    engine_on_time_start_at: 'เวลาติดเครื่องเริ่มต้น',
    engineOff: 'ดับเครื่อง',
    engineOffDesc: 'ตำแหน่งที่ส่งข้อมูล GPS ขณะดับเครื่อง',
    engineOn: 'ติดเครื่อง',
    engineOnCombine: 'ติดเครื่อง',
    engineOnDesc: 'ตำแหน่งที่ส่งข้อมูล GPS ขณะติดเครื่อง',
    engineOnDuration: 'ระยะเวลาติดเครื่องยนต์',
    engineOnWithImg: 'ติดเครื่องพร้อมข้อมูลรูปภาพ',
    engineOnWithImgDesc:
      'ตำแหน่งที่ส่งข้อมูล GPS พร้อมรูปภาพจาก Dash camera ขณะติดเครื่อง',
    engineStatus: 'สถานะเครื่องยนต์',
    estFare: 'ค่าโดยสาร',
    estimatedDeliveryTime: 'เวลาจัดส่งโดยประมาณ',
    estTransitDistance: 'ระยะทางการเดินทาง',
    estTransitDuration: 'ระยะเวลาการเดินทาง',
    etc: 'อื่นๆ',
    europeAmerican: 'ยุโรป/อเมริกัน',
    event: 'เหตุการณ์',
    eventAlarm: 'Event Alarm',
    eventEnd: 'จบเหตุการณ์',
    eventMap: 'ภาพรวมพิกัดการฝ่าฝืน',
    EventReport: 'เหตุการณ์พาหนะรายคัน',
    excel: 'Excel',
    expand: 'ขยาย',
    expense: 'ค่าใช้จ่าย',
    expenseBaht: 'ค่าใช้จ่าย (บาท)',
    expireDate: 'วันที่หมดอายุ',
    expiryDate: 'วันหมดอายุ',
    expiredAt: 'หมดอายุเมื่อ',
    export: 'ส่งออกข้อมูล',
    export_date_at: 'วันที่ออก',
    extra: 'เพิ่มเติม',
    getVehicles: 'แสดงพาหนะ',
    SafetyEventReport: 'ความปลอดภัยพาหนะ',
    enterEmailToReceiveCredentials: 'ระบุ email เพื่อรับหนังสือรับรอง',
    exportUpTo50000RowsAtaTime: 'Export ได้สูงสุดครั้งละ 50000 rows',
    editItemsOnly: 'แก้ไขข้อมูลเท่านั้น',
    end: 'สิ้นสุด',
    emptyCar: 'รถว่าง',
    endTime: 'เวลาสิ้นสุด',
    end_the_concrete_into_the_truck: 'จบปล่อยคอนกรีตลงรถ',
  },
  event: {
    eventSetting: 'ตั้งค่าเหตุการณ์',
    acc: 'ความเร่งผิดปกติ',
    approaching_point: 'ใกล้ถึงจุดหมาย',
    approaching_point_but_late: 'รถใกล้ถึงแต่สาย',
    arrival_but_late_departure: 'รถอยู่ที่หมายแต่จะออกจากจุดสาย',
    arrival_point: 'รถอยู่ที่หมาย',
    blackbox_restart: 'กล่องรีสตาท',
    boom_ready: 'ยกบูม',
    discharge_concrete: 'หมุนเทปูน',
    driver_rest_lower_than_10_hours: 'Driver rest lower than 10 hours',
    engineoff: 'ดับเครื่องยนต์',
    engineon: 'ติดเครื่องยนต์',
    fuel_anomaly: 'ปริมาณน้ำมันผิดปกติ',
    hard_brake: 'เบรคแรง',
    hard_turn: 'เข้าโค้งแรง',
    high_beam_off: 'ปิดไฟสูง',
    high_beam_on: 'เปิดไฟสูง',
    high_rpm: 'รอบเครื่องสูงเกินกำหนด',
    hit_the_brake: 'เหยียบเบรค',
    honk: 'บีบแตร',
    into_and_out_of_specific_areas: 'เข้าออกพื้นที่เฉพาะ',
    late: 'ถึงจุดหมายสาย',
    long_idle_time: 'ติดเครื่องอยู่กับที่',
    low_beam_off: 'ปิดไฟต่ำ',
    low_beam_on: 'เปิดไฟต่ำ',
    low_speed_more_than_10_minute: 'วิ่งความเร็วต่ำต่อเนื่องมากกว่า 10 นาที',
    mixing_concrete: 'หมุนโหลดปูน',
    move_in_point_late: 'Move in point late',
    move_out_load_point_with_out_ticket: 'ออกจากแพล้นโดยไม่มีตั๋ว',
    move_out_point_late: 'Move out point late',
    off_route: 'ออกนอกเส้นทาง',
    open_door: 'เปิดตู้',
    open_door_outside_point: 'เปิดตู้นอกจุดส่ง',
    over_driving_hour_limit: 'วิ่งต่อเนื่องเกินกำหนด',
    poweroff: 'ปิดเครื่องส่งสัญญาณ',
    poweron: 'เปิดเครื่องส่งสัญญาณ',
    pto_off: 'ปิด PTO',
    pto_on: 'เปิด PTO',
    pump_on: 'เปิดปั๊ม',
    refuel: 'เติมน้ำมัน',
    refuel_full: 'เติมน้ำมันเต็มถัง',
    refuel_not_full: 'เติมน้ำมันไม่เต็มถัง',
    release_the_brake: 'หยุดเหยียบเบรค',
    reversing_light_off: 'ปิดไฟถอยหลัง',
    reversing_light_on: 'เปิดไฟถอยหลัง',
    shock: 'สะเทือน',
    speed_anomaly: 'ความเร็วผิดปกติ',
    stop_honk: 'หยุดบีบแตร',
    stop_in_area: 'จอดรถในพื้นที่ที่กำหนด',
    stop_mixing_concrete: 'โม่หยุดหมุน',
    summary_idle_limit: 'สรุปรถติดเครื่องอยู่กับที่',
    summary_low_speed_more_than_10_minute:
      'สรุปวิ่งความเร็วต่ำต่อเนื่องมากกว่า 10 นาที',
    summary_over_driving_hour_limit: 'สรุปวิ่งต่อเนื่องเกินกำหนด',
    temperature_anomaly: 'อุณภูมิผิดปกติ',
    truck_stay_in_plant_more_than_limit: 'Truck stay in plant more than limit',
    turn_signal_off: 'ปิดไฟเลี้ยว',
    turn_signal_on: 'เปิดไฟเลี้ยว',
    unauthorized_discharge: 'เทปูนนอกไซต์งาน',
    windshield_wipers_off: 'ปิดที่ปัดน้ำฝน',
    windshield_wipers_on: 'เปิดที่ปัดน้ำฝน',
    work_longer_than_the_time_limit: 'Work longer than the time limit',
    working_distance_less_than_the_rule: 'Working distance less than the rule',
    working_over_limit: 'Working over limit',
    working_within_a_given_period: 'Working within a given period',
  },
  f: {
    factoryWhereVehicleParkedWithinRadius: 'โรงงานที่รถจอดภายในรัศมี (กม.)',
    factoryWhereVehicleParked: 'โรงงานที่รถจอด',
    fuelConsumption: 'อัตราการกินน้ำมัน',
    fillYourNote: 'ระบุหมายเหตุของท่าน',
    filterIcons: 'ฟิลเตอร์ไอคอน',
    fast: 'เร็ว',
    freeChoice: 'ค้นหาอิสระ',
    findTheDesiredLocation: 'ค้นหาตำแหน่งที่ต้องการ',
    fillLatLng: 'กรุณากรอก: ละติจูด, ลองจิจูด',
    finished: 'เสร็จแล้ว',
    findCarThisArea: 'ค้นหาบริเวณนี้',
    findThisArea: 'ค้นหาบริเวณนี้',
    filterNode: 'กรอง Node',
    frequency: 'ความถี่',
    fullname: 'ชื่อ-นามสกุล',
    fleetName: 'ชื่อฟลีท',
    finish_date: 'วันเวลาที่ปิดเคส',
    finishedPouring: 'เทเสร็จ',
    finishedPouringConcreteDashboard: 'เทเสร็จสิ้นแล้ว',
    filterthelastdepartment: 'ใช้ค่ากรองฝ่ายส่วนครั้งล่าสุด',
    filterlatest: 'ใช้ค่ากรองครั้งล่าสุด',
    factory: 'โรงงาน',
    FactoryWantToUseVehicle: 'โรงงานที่ต้องการใช้รถ',
    factoryCode: 'รหัสโรงงาน',
    favoriteMenu: 'เมนูที่ชื่นชอบ',
    facilityName: 'ชื่อสถานที่',
    facilityName2: 'ชื่อสถานที่2',
    facilityName3: 'ชื่อสถานที่3',
    fail: 'ไม่สำเร็จ',
    fare: 'ค่าโดยสาร',
    file: 'ไฟล์',
    file_location: 'สถานที่เก็บไฟล์',
    file_size: 'ขนาดไฟล์',
    fileapetition: 'ยื่นคำร้อง',
    fileMustSmallerThan2MB: 'ไฟล์ต้องมีขนาดเล็กกว่า 2MB!',
    filenotreadydownload: 'ไม่สามารถดาวน์โหลดไฟล์ได้ในขณะนี้',
    fillInfomation: 'กรอกข้อมูล',
    fillInForm: ' เติมข้อมูลลงในฟอร์ม',
    fillInFrom: 'Fill In From',
    fillPosition: 'ป้อนตำแหน่ง',
    filter: 'ค้นหา',
    filter_by: 'ค้นหาข้อมูลโดย',
    filter_type: 'รูปแบบ',
    filter_type1: 'ออกจากโรงงานครั้งแรกถึงเข้าโรงงานครั้งสุดท้าย',
    filter_type2: 'ติดเครื่องครั้งแรกถึงดับเครื่องครั้งสุดท้าย',
    findCustomer: 'ค้นหาลูกค้า',
    findDistance: 'หาระยะทาง',
    findRoute: 'หาเส้นทาง',
    findSpace: 'หาพื้นที่',
    fine: 'ปรับ',
    finish: 'เสร็จสิ้น',
    finished: 'เสร็จสิ้น',
    finishRefuel: 'จบการเติมเชื้อเพลิง',
    firmware: 'เฟิร์มเเวร์',
    firmwareNumber: 'เลขเฟิร์มเเวร์',
    firmwareVersion: 'เวอร์ชันเฟิร์มแวร์',
    first_dest: 'จุดเริ่มต้น',
    firstMaintenanceConditions: 'เงื่อนไขการซ่อมบำรุงครั้งแรก',
    firstName: 'ชื่อ',
    firstNameisCharacters: 'ชื่อต้องเป็นตัวอักษร',
    fixed: 'ซ่อม',
    flateNumber: 'Flate Number',
    flatRate: 'เหมา',
    floor: 'พื้น',
    forceUpdate: 'บังคับอัพเดท',
    forgot_password: 'ลืมรหัสผ่าน',
    from: 'จาก',
    front: 'กล้องนอกรถ',
    fuel: 'เชื้อเพลิง',
    fuelA: 'Fuel A',
    fuelB: 'Fuel B',
    fuelC: 'Fuel C',
    fuelFull: 'น้ำมันเต็มถัง',
    fuelRate: 'น้ำมันคงเหลือ (ลิตร)',
    fuelremainrateReport: 'น้ำมันคงเหลือ',
    fuelTankSize: 'ความจุถังเชื้อเพลิง',
    fuelType: 'ประเภทเชื้อเพลิง',
    fuelUsage: 'อัตราสิ้นเปลืองเชื้อเพลิง',
    fullScreen: 'ขยายเต็มจอ',
    Fullscreen: ' ขยายเต็มจอ',
    fullScreenExit: 'ออกจากโหมดเต็มจอ',
    fullTank: 'เต็มถัง',
    factory: 'โรงงาน',
    FueluseReport: 'การคำนวณปริมาณการใช้น้ำมันของพาหนะ',
    failed: 'ล้มเหลว',
    fleetStatus: 'สถานะกองรถ',
    fleetEfficiency: 'ประสิทธิภาพของกองรถ',
    floorWallDelivery: 'จัดส่ง พื้น-ผนัง',
    fromAnotherSystem: 'จากระบบอื่น',
    failedToOpenTicketAtTerminus: ' เปิดตั๋วที่ TERMINUS ไม่สำเร็จ',
    forceToTakePicturesSeal: 'บังคับถ่ายภาพคลุมผ้าใบ',
    forceToTakePicturesSendWork: 'บังคับถ่ายภาพส่งงาน',
    fleetManagement: 'ระบบจัดการกองรถ',
    factoryArrivingTime: 'เวลากลับเข้าโรงงาน',
  },
  g: {
    goodDP: 'DP ดี',
    generate: 'สร้าง',
    generateTokenAccount: 'สร้างบัญชีโทเค็น',
    generateTokenResult: 'ผลลัพธ์สร้างโทเค็น',
    generateToken: 'สร้างโทเค็น',
    gpsWorking: 'ใช้งานได้',
    gpsNotWorking: 'ใช้งานไม่ได้',
    gpsUpdateAt: 'GPS ของรถโม่อัพเดทล่าสุดเมื่อ ',
    gpsDistance: 'ระยะ GPS',
    grade: 'เกรดที่ได้',
    gpsProviderChoose: 'กรุณาเลือกผู้ให้บริการGPS',
    gpsProvider: 'ผู้ให้บริการ GPS',
    goback: 'เดินทางกลับ',
    goto: 'เดินทางไป',
    gpsStatus: 'สถานะ GPS',
    gallery: 'แกลเลอรี่',
    gallery_name: 'ชื่อ',
    gate1: 'ประตู 1',
    gate2: 'ประตู 2',
    gate3: 'ประตู 3',
    general: 'ทั่วไป',
    general_report_menu: 'ทั่วไป',
    generalCurve: 'โค้งทั่วไป',
    generalLocation: 'General Location',
    generateMap: 'สร้างแผนที่',
    goBackToUseImagesByLocationType: 'กลับไปใช้รูปภาพตามประเภทสถานที่',
    googleMapApiKey: 'Google Map Api Key',
    gps_update: 'ตำแหน่งล่าสุด',
    gpsCertification: 'ใบรับรองการติดตั้ง',
    gpsCertRequest: 'ขอใบรับรองการตืดตั้ง',
    gpsCertRequestHistory: 'ประวัติการขอใบรับรองการติดตั้ง',
    gpsNotFixed: 'GPS ไม่เกาะ',
    gpsNotFixedDesc: 'พิกัดไม่แม่นยำ อาจทำให้ตำแหน่งคลาดเคลื่อนจากความเป็นจริง',
    gpsPerformanceDashboard: 'ประสิทธิภาพ GPS',
    GpsstatusReport: 'พาหนะที่สัญญาณขาดหาย',
    gpsVendor: 'ผู้ให้บริการ GPS',
    gpsVendorPayingReport: 'สรุปค่าบริการตาม GPS Vendor',
    gpsVendorPayingSummaryReport: 'สรุปค่าบริการ GPS ทั้งหมด',
    gradeAtoF: 'เกรด (A ถึง F)',
    greater_than: 'มากกว่า',
    greaterOrEqual: 'มากกว่าเท่ากับ',
    group: 'กลุ่ม',
    group_by: 'จัดกลุ่มโดย',
    group_data: 'Group ข้อมูล',
    group_idle: 'เฉพาะจอดติดเครื่อง',
    group_speed: 'เฉพาะความเร็วเกิน',
    groupByLocation: 'จัดกลุ่มตามสถานที่',
    groupVehicleType: 'กลุ่ม/ประเภทพาหนะ',
    gpsReportLostContact: 'GPS ขาดการติดต่อ',
    gpsDisconnectOnTrip: 'GPS ขาดการติดต่อขณะวิ่งงาน',
    gpsPerformanceDesGPS:
      'คิดจากการใช้งานได้ในช่วงเวลาที่วิ่งงานแต่ละตั๋ว โดยหักเวลาก่อนแจ้งซ่อมออก ในกรณีที่ขาดการติดต่อไม่สามารถจับเวลาการทำงานได้ จะคิดเวลาการทำงานแต่ละตั๋วตั้งแต่เวลาผลิตเสร็จ + 3 ชม.',
    gpsPerformanceDesDrumSensor:
      'จำนวนตั๋วที่มีการเทปูน เทียบกับตั๋วที่มีการเข้าไซต์งาน',
    gpsPerformanceDesCard: 'จำนวนวันที่รูดบัตร เทียบกับจำนวนวันที่วิ่งงาน',
    gpsPerformanceDesRed: '< 95% (สำหรับเดือนที่ผ่านมา)',
    gpsPerformanceDesYellow: '< 95% (สำหรับเดือนปัจจุบัน)',
    gpsPerformanceDesGreen: '>= 95%',
    drumSensorPerformanceDesRed: '< 80% (สำหรับเดือนที่ผ่านมา)',
    drumSensorPerformanceDesYellow: '< 80% (สำหรับเดือนปัจจุบัน)',
    drumSensorPerformanceDesGreen: '>= 80%',
    gpsPerformanceDesNA: 'ไม่สามารถประเมินได้เนื่องจากข้อมูลไม่พอ',
    generateqrCodeBy: 'Generate QR Code โดย',
    generateqrCodeInArea: 'Generate QR Code ในพื้นที่',
    grConditions: 'เงื่อนไข GR',
    gpsActive: 'GPS ทำงาน',
    gpsNotActive: 'GPS ไม่ทำงาน',
    gpsTime: 'เวลา GPS',
    groupName: 'ชื่อกลุ่ม',
  },
  h: {
    humus: 'ดินดำ',
    hideLocation: 'ซ่อนสถานที่',
    half: 'Half',
    hardwareID: 'รหัสฮาร์ดแวร์',
    header: 'หัวข้อ',
    height: 'สูง',
    help_menu: 'ช่วยเหลือ',
    high: 'สูง',
    history_servicerepairdevice: 'ประวัติ',
    home: 'หน้าหลัก',
    honeytoastdownloadfile: 'ดาวน์โหลดวิดีโอ',
    honeytoastlivestream: 'วิดีโอเรียลไทม์',
    honeytoastplayback: 'วิดีโอย้อนหลัง',
    horsePower: 'เเรงม้า',
    hotel: 'โรงเเรม',
    hour: 'ชั่วโมง',
    hours: 'ชม.',
    honeyToast: 'Video',
  },
  i: {
    idCardNotMatch: 'เลขบัตรประชาชนไม่ตรง',
    id_card: 'เลขบัตรประชาชน',
    is_pay: 'การชำระค่าบริการ',
    is_pay_gps: 'ชำระค่าบริการเฉพาะ GPS',
    is_pay_sensor: 'ชำระค่าบริการเฉพาะ Sensor โม่',
    is_geofence: 'พื้นที่ไซต์งานเป็น Geofence หรือไม่ ?',
    informer: 'ผู้แจ้ง',
    timestampissueticket: 'เวลาออกตั๋ว',
    issueticket: 'ออกตั๋ว',
    iconDesc: 'รายละเอียดไอคอน',
    iconStatus: 'รายละเอียดไอคอน',
    id: 'ID',
    idCard: 'ID_Card',
    idleFuelRate: 'Idle Fuel Rate',
    ignoredAlert: 'ไม่แจ้งเตือน',
    imageFromDashCamera: 'ภาพจากกล้อง',
    imageFromTheGallery: 'รูปจากแกลเลอรี่',
    imageMustSmallerThan5MB: 'รูปภาพต้องมีขนาดเล็กกว่า 5MB',
    imagesNotUpdate: 'รูปภาพไม่อัพเดท',
    immediatelyAcess: 'ทันที',
    immediatelyBreak: 'เบรคกระทันหัน (ครั้ง)',
    import: 'นำเข้าข้อมูล',
    include_engine_off: 'รวมข้อมูลดับเครื่อง',
    indexFilterOption_All: 'ทั้งหมด',
    indexFilterOption_Cancel: 'ยกเลิก',
    indexFilterOption_Confirm: 'ยืนยัน',
    indexFilterOption_Finish: 'เสร็จสิ้น',
    indexFilterOption_Unassign: 'ยังไม่มีพนักงานขับขี่',
    indexFilterOption_Waiting: 'รอพนักงานขับขี่ยืนยัน',
    indian: 'อินเดีย',
    inEvery: 'ในทุกๆ',
    infomation: 'ข้อมูล',
    inform_date: 'วันที่แจ้ง',
    informationFor: 'ข้อมูลของ',
    insertSuccess: 'เพิ่มข้อมูลสำเร็จ',
    insertFail: 'เพิ่มข้อมูลไม่สำเร็จ',

    inside: 'ด้านใน',
    install: 'ติดตั้ง',
    install_at: 'ติดตั้งเมื่อ',
    installDevice: 'ติดตั้งอุปกรณ์',
    installed: 'ติดตั้งเเล้ว',
    installStatus: 'สถานะการติดตั้ง',
    insurance: 'ประกันภัยรถยนต์',
    insurance_report_menu: 'ประกันภัยพาหนะ',
    insuranceID: 'หมายเลขกรมธรรม์',
    InsuranceReport: 'รายงานประกันภัยพาหนะ',
    insure_report_menu: 'ประกันภัย',
    internalOperation: 'การจัดการภายใน',
    internet: 'อินเตอร์เน็ต',
    invoice_date: 'วันที่ใบส่งของ',
    invoice_number: 'เลขที่ใบส่งของ',
    issuedDate: 'วันที่ออก',
    isupplyManageVehicle: 'การจัดคิวรถ',
    itemLeft: 'เหลือ',
    itemSelected: 'เลือก',
    inMaintenance: 'รถที่ไม่พร้อมใช้งาน',
    isupplyManageQueue: 'การจัดการคิวรถ',
    informationAboutTheCarRunWall: 'ข้อมูล เเผนการวิ่งรถ (ผนัง)',
    informationAboutTheCarRunFloor: 'ข้อมูล เเผนการวิ่งรถ (พื้น)',
    import: 'นำเข้าข้อมูล',
    importMutipleCompany: 'นำเข้าข้อมูล (หลายบริษัท)',
    importUpTo1000RowsAtATime:
      'นำเข้าข้อมูลได้ไม่เกินครั้งละ 1,000 แถว เท่านั้น',
    isupplySource: 'ต้นทาง',
    isupplyPlant: 'ปลายทาง',
    interfaceGR: 'Interface GR',
    isupplyQR: 'สเเกน QR Code',
    isupplyCDASE: 'รับค่าผ่าน API',
    isupplyFIXED: 'น้ำหนักตามสัญญา',
    isupplyMANUAL: 'ระบุโดยพนักงานขับขี่',
    isupplySeal: 'คลุมผ้าใบ',
    issupplyDP: 'ใบ DP',
    inTheSystem: 'ในระบบ',
    isupplySourceCodeRequest: 'ระบุ ปลายทาง',
    isupplySystemKey: 'Interface CDAS',
    isupplyProduct: 'สินค้า',
    inFrontOfTheCarYouCanSeeTheRegistrationPlateAndTheDropOffPoint:
      'หน้ารถเห็นทะเบียนเเละจุดลงสินค้า',
    InProcessOfDeliveryTheTicketGroupCannotBeEdited:
      'อยู่ระหว่างการจัดส่ง ไม่สามารถแก้ไขกลุ่มตั๋วได้',
    isupplyMultidrop: 'การจัดกลุ่มใบงาน',
    imageTitle: 'ชื่อภาพตัวอย่าง',
    isupplyBookingOrder: 'แผนงานกองรถ',
    isupplyCustomerName: 'ชื่อ-นามสกุลผู้ใช้งาน',
    isupplyCustomerNameIsRequired: 'กรุณาระบุ ชื่อ-นามสกุลผู้ใช้งาน',
    isupplyCustomerPhone: 'เบอร์โทรติดต่อผู้ใช้งาน',
    isupplyCustomerPhoneIsRequired: 'กรุณาระบุ เบอร์โทรติดต่อผู้ใช้งาน',
    isupplyCustomerInformation: 'ข้อมูลผู้ใช้งาน',
    informationMustBeUnique: 'ข้อมูลต้องไม่ซ้ำ',
    isupplyBookingDashboard: 'ปฏิทินงานกองรถ',
    isupplySaleOrder: 'รายการสั่งวัตถุดิบแบบกองงาน',
  },
  j: {
    job: 'งาน',
    jobDetail: 'รายละเอียดงาน',
    jobId: 'รหัสงาน',
    jobStartAt: 'เวลาเข้างาน',
    jobStatus: 'สถานะงาน',
    jobType: 'ประเภทงาน',
    jobTypeSTATISTICS: 'สถิติประเภทของงาน',
    joinDate: 'วันที่เริ่มงาน',
    jobSiteGroup: 'กลุ่มไซต์งาน',
    jobInformation: 'ข้อมูลงาน',
    jobSiteSurvey: 'ข้อมูลการสำรวจหน้างาน',
  },
  k: {
    keywordAtleast3character: 'คำหลักอย่างน้อย 2 ตัวอักษร',
    kind: 'ชนิด',
    km: 'กม.',
    kmPerLite: 'กม./ลิตร',
    kmH: 'กม./ชม.',
    kpiDateTime: 'วันที่/เวลา',
    kpiEvent: 'เหตุการณ์',
    kg: 'กก.',
  },
  l: {
    lastUpdatedData: 'ข้อมูลอัพเดทล่าสุดเมื่อ ',
    linkForDisplayMovingPoint: 'Link แสดงการย้ายจุด',
    loginByToken: 'ล็อคอินด้วย Token',
    lastUpdatedOn: 'ข้อมูลของวันที่',
    LeavingPlantLateCheckThePlantGeofence:
      'ออกจากแพลนต์ช้า เช็ค geofence ของแพลนต์',
    LateToTheAgencyCheckTheAgencyGeofence:
      'ถึงหน่วยงานช้า เช็ค geofence ของหน่วยงาน',
    largeTruck: 'รถโม่ใหญ่',
    lessThan3days: 'น้อยกว่า 3 วัน',
    load_amount: 'ปริมาณที่โหลด (คิว)',
    load_time: 'ระยะเวลาโหลด (ชั่วโมง)',
    load_end: 'จบการโหลด',
    load_start: 'เริ่มโหลด',
    largeMixer: 'โม่ใหญ่',
    largeMixerAndSmallMixer: 'โม่เล็กและโม่ใหญ่',
    lastest_signal: 'สัญญาณล่าสุด',
    lastmaintenanceAt: 'การซ่อมบำรุงครั้งล่าสุด',
    lastName: 'นามสกุล',
    LastNameisCharacters: 'นามสกุลต้องเป็นตัวอักษร',
    lastQueue: 'คิวล่าสุด',
    lastRequestOn: 'คำขอล่าสุดเมื่อ',
    late: 'ที่สาย',
    latePoint: 'จุดที่เข้าสาย ค่าเกณฑ์',
    latestDriver: 'พนักงานขับขี่ล่าสุด',
    latitude: 'ละติจูด',
    latitudeIsRequired: 'กรุณากรอกละติจูด',
    latitudeIsRequiredAndCantBeEmpty: 'กรุณากรอกละติจูด',
    latlng: 'ละติจูด,ลองจิจูด',
    lazada: 'ลาซาด้า',
    leaveTheOriginLocation: 'ออกจากบริเวณต้นทาง',
    length: 'ความยาว',
    less_than: 'น้อยกว่า',
    lessOrEqual: 'น้อยกว่าเท่ากับ',
    lightStatus: 'สถานะไฟ',
    limit10Driver: 'จำนวนพนักงานขับขี่ที่เลือกต้องไม่เกิน 10 คน',
    limitAggesiveAccelerationThreshold: 'เร่งแรงได้ไม่เกิน',
    limitDrivingAggesiveThreshold: 'ขับพาหนะจี้ท้ายได้ไม่เกิน',
    limitDrivingHourTime: 'จำกัดระยะเวลาวิ่งงานต่อเนื่อง (นาที)',
    limitGoInsideArea: 'ห้ามเข้าพื้นที่เกิน',
    limitGoOutsideArea: 'ห้ามออกพื้นที่เกิน',
    limitOpenDuration: 'ห้ามเปิดเกิน',
    limitParking: 'ห้ามจอดเกิน',
    limitParkingDuration: 'ห้ามจอดเกิน',
    limitParkingInsideLocation: 'ห้ามจอดในสถานที่เกิน',
    limitParkingOutsideLocation: 'ห้ามจอดนอกสถานที่เกิน',
    limitSnoozeThreshold: 'สุ่มเสี่ยงหลับในได้ไม่เกิน',
    limitSuddenlyBreakThreshold: 'เบรคแรงได้ไม่เกิน',
    limitUsingPhoneThreshold: 'ใช้โทรศัพท์ขณะขับขี่ได้ไม่เกิน',
    link: 'ลิ้งค์',
    liveStream: 'วิดีโอเรียลไทม์',
    load: 'โหลด',
    loadAmount: 'จำนวนโหลด',
    loadpoint: 'จุดโหลดของ',
    loadStartAt: 'โหลดเมื่อ',
    location: 'สถานที่',
    Location: 'สถานที่',
    locationAndRoute: 'สถานที่และเส้นทาง',
    locationBlacklist: 'Locations Blacklist',
    locationCode: 'รหัสสถานที่',
    locationCodeIsRequired: 'กรุณากรอกรหัสสถานที่',
    locationGroup: 'กลุ่มสถานที่',
    locationGroupName: 'ชื่อกลุ่มสถานที่',
    locationGroupNameIsRequired: 'กรุณากรอกชื่อกลุ่มสถานที่',
    locationGroupTypeIsRequired: 'กรุณาเลือกประเภทกลุ่มสถานที่',
    locationInformation: 'ข้อมูลสถานที่',
    LocationInSystem: 'สถานที่ที่มีอยู่ในระบบ',
    locationName1IsRequired: 'กรุณากรอกชื่อสถานที่',
    LocationOfDeviceUser: 'สถานที่ต้นสังกัดของพนักงานขับขี่',
    LocationOfVehicle: 'สถานที่ต้นสังกัดของพาหนะ',
    locationShareFrom: 'สถานที่ที่ถูกเเชร์',
    locationShareTo: 'สถานที่ที่เเชร์',
    locationTemporary: 'สถานที่ชั่วคราว',
    locationType: 'ประเภทสถานที่',
    login: 'เข้าสู่ระบบ',
    login_fail: 'เข้าระบบไม่สำเร็จ',
    login_success: 'เข้าระบบสำเร็จ',
    LoginhistoryReport: 'ประวัติการเข้าใช้งานระบบรายคน',
    logout: 'ออกจากระบบ',
    longdoMapApiKey: 'Longdo Map Api Key',
    longitude: 'ลองจิจูด',
    longitudeIsRequired: 'กรุณากรอกลองจิจูด',
    LongitudeIsRequiredAndCantBeEmpty: 'กรุณากรอกลองจิจูด',
    lookingForSomething: 'ป้อนตำแหน่ง',
    lostConnectio: 'ขาดการติดต่อ',
    low: 'ต่ำ',
    linenotify: 'ตั้งค่า Line Notify',
    line_token: 'Line Token',
    line_token_require: 'กรุณากรอก Line Token',
    litre: 'ลิตร',
    litrePerTrip: 'ลิตร/เที่ยว',
    line: 'บรรทัด',
    localDelivery: 'ส่งในพื้นที่',
    localDeliveryOnly: 'ส่งในพื้นที่ เท่านั้น',
    locationSharingStatus: 'สถานะการเเชร์ สถานที่',
    lineId: 'ไอดีไลน์',
    landmark: 'จุดสังเกต',
    locale: 'th-TH',
    load_outsidepoint_time: 'เวลาที่เทปูนนอกจุด',
    load_outsidepoint_duration: 'ระยะเวลาที่เทปูนนอกจุด (นาที)',
    load_outsidepoint_location: 'ตำแหน่งที่เทปูนนอกจุด',
    load_outsidepoint_speed: 'ความเร็วสูงสุดช่วงเกิดการเทปูน (km/h)',
    loading: 'กำลังโหลด',
    lastUsedFactionFilterValue: 'ใช้ค่ากรองฝ่ายส่วนครั้งล่าสุด',
    log_type: 'สาเหุตการเปลี่ยนแปลงข้อมูลตั๋ว',
    log_at: 'เวลาการเปลี่ยนแปลงข้อมูลตั๋ว',
  },
  locationType: {
    All: 'ทั้งหมด',
    BS: 'ป้ายรถเมย์หลัก',
    BS1: 'ป้ายรถเมย์ 1',
    BS2: 'ป้ายรถเมย์ 2',
    CarService: 'ศูนย์บริการ',
    GasStation: 'ปั้มน้ำมัน',
    GasCost: 'ค่าน้ำมัน',
    GasCostBath: 'ค่าน้ำมันต่อ กม.',
    NoParking: 'จุดห้ามจอด',
    OnSite: 'เเพลนท์ออนไซต์',
    ParkingLot: 'ลานจอดพาหนะ',
    Plant: 'จุดโหลดของ',
    PreparePoint: 'จุดเตรียมความพร้อม',
    RestArea: 'จุดพักพาหนะ',
    Site: 'จุดส่งของ',
    Temporary: 'สถานที่ชั่วคราว',
    Mill: 'ต้นทาง',
    MaterialPickUpPoint: 'จุดรับวัสดุ',
    Unload: 'จุดเท',
  },
  m: {
    mixedEngineTime: 'ระยะเวลาที่รถวิ่ง',
    maxUsedSpeed: 'ความเร็วสูงสุดที่ใช้',
    minutesAgo: ' นาทีที่แล้ว',
    monthCount: 'เดือนที่',
    moveTime: 'เริ่มเคลื่อนที่ (วัน-เวลา,สถานที่)',
    mapview: 'มุมมองแผนที่',
    map: 'ดูแผนที่',
    mixerSize: 'ขนาดโม่',
    m: 'เมตร',
    meterConcreteDashboard: 'ม.',
    m_april: 'เมษายน',
    m_august: 'สิงหาคม',
    m_december: 'ธันวาคม',
    m_february: 'กุมภาพันธ์',
    m_january: 'มกราคม',
    m_july: 'กรกฎาคม',
    m_june: 'มิถุนายน',
    m_march: 'มีนาคม',
    m_may: 'พฤษภาคม',
    m_november: 'พฤศจิกายน',
    m_october: 'ตุลาคม',
    m_september: 'กันยายน',
    machineSerialNumber: 'หมายเลขเครื่องยนต์',
    maintenance: 'การบำรุงรักษา',
    maintenance_status: 'สถานะ',
    maintenance_type: 'ประเภทการแจ้ง',
    maintenanceConditions: 'เงื่อนไขการซ่อมบำรุง',
    maintenancedevice: 'แจ้งซ่อมอุปกรณ์',
    maintenancedevice_report_menu: 'การจัดการอุปกรณ์',
    MaintenancedeviceconcludeReport: 'การซ่อม/ถอดอุปกรณ์',
    MaintenanceReport: 'พาหนะที่ต้องบำรุงรักษา',
    manage_product: 'จัดการสินค้า',
    management_menu: 'การจัดการ',
    managementdevice_report_menu: 'การจัดการอุปกรณ์',
    manpowerRate: 'อัตรากำลัง',
    map: 'แผนที่',
    mapType: 'Map Type',
    mapView: 'มุมมองเเผนที่',
    masterData: 'ข้อมูลหลัก',
    match: 'จับคู่',
    masterDataMatrial: 'วัตถุดิบ',
    material: 'วัตถุดิบ',
    materialCode: 'รหัส วัตถุดิบ',
    materialName: 'ชื่อ วัตถุดิบ',
    materialCodeRequest: 'ระบุ รหัส วัตถุดิบ',
    materialNameRequest: 'ระบุ ชื่อ วัตถุดิบ',
    materialRequest: 'วันที่ต้องการวัตถุดิบ',
    materialWasNotFound: 'ไม่พบวัตถุดิบในระบบ',
    material_code: 'รหัสสินค้า',
    material_description: 'ชื่อสินค้า',
    max_speed: 'ความเร็วสูงสุด (กม./ชม.)',
    maxDrumCapacity: 'ความจุโม่สูงสุด',
    mdvrdownloadfile: 'ดาวน์โหลดวิดีโอย้อนหลัง MDVR',
    medium: 'ปานกลาง',
    message: 'ข้อความ',
    meter: 'มิเตอร์',
    meterDashCameras: 'มิเตอร์',
    meterSN: 'Meter S/N',
    meterStatus: 'สถานะ Meter',
    mileNo: 'เลขไมล์',
    min: 'นาที',
    min_speed: 'ความเร็วสูงสุด(ขั้นต่ำ)',
    Minimal: 'อย่างย่อ',
    minute: 'นาที',
    miss: 'นางสาว',
    mixingDrum: 'โม่ผสมปูน',
    model: 'รุ่น',
    moisture: 'ความชื้น',
    monitoring: 'ระบบติดตาม',
    month: 'เดือน',
    monthly: 'รายเดือน',
    more_1_min: 'มากกว่า 1 นาที',
    more_10_mins: 'มากกว่า 10 นาที',
    more_10_sec: 'มากกว่า 10 วินาที',
    more_30_sec: 'มากกว่า 30 วินาที',
    more_5_mins: 'มากกว่า 5 นาที',
    more_5_sec: 'มากกว่า 5 วินาที',
    more_60_sec: 'มากกว่า 60 วินาที',
    move: 'ย้าย',
    move_in: 'เข้าจุด',
    move_out: 'ออกจุด',
    moveDevice: 'เเจ้งย้ายอุปกรณ์',
    mr: 'นาย',
    mrs: 'นาง',
    materialGroupType: 'ประเภทกลุ่มวัตถุดิบ',
    multiDrop: 'ติดตามสถานะการจัดส่งทริป',
    mdvr: 'MDVR',
    menu: 'เมนู',
    maxOverLimitTime: 'เวลาสูงสุด (ชั่วโมง:นาที:วินาที)',
    multiDropDescIncomingTrip: 'กำลังเข้าจุด',
    multiDropDescIncomingTripDesc: 'พาหนะกำลังเดินทางไปยังจุดหมายที่กำหนด',
    multiDropDescIncomingLateTrip: 'กำลังเข้าจุด(สาย)',
    multiDropDescIncomingLateTripDesc:
      'พาหนะกำลังเดินทางไปยังจุดหมายที่กำหนด และล่าช้ากว่าเวลาแผน',
    multiDropDescAlreadyVisitTrip: 'เข้าจุด',
    multiDropDescAlreadyVisitTripDesc: 'พาหนะถึงจุดหมาย (ตรงเวลา)',

    multiDropDescVisited: 'ยังไม่เข้าจุด',
    multiDropDescVisitedDesc: 'พาหนะยังไม่ถึงจุดหมาย',
    multiDropDescVisitedConfirm: 'รอยืนยัน',
    multiDropDescVisitedConfirmDesc:
      'พาหนะถึงจุดหมายและอยู่ระหว่างยืนยันการเข้าจุด (อยู่ในสถานที่เกิน 3 นาที)',
    multiDropDescVisitedLate: 'เข้าจุดสาย',
    multiDropDescVisitedLateDesc: 'พาหนะถึงจุดหมายล่าช้ากว่าเวลาแผน',
    multiDropDescVisitedOnTime: 'เข้าจุด',
    multiDropDescVisitedOnTimeDesc: 'พาหนะถึงจุดหมาย (ตรงเวลา)',

    multiDropDescLeave: 'ยังไม่ออกจุด',
    multiDropDescLeaveDesc: 'พาหนะยังไม่ออกจากจุดหมาย',
    multiDropDescLeaveConfirm: 'รอยืนยัน',
    multiDropDescLeaveConfirmDesc:
      'พาหนะออกจุดหมายและอยู่ระหว่างยืนยันการออกจุด (ออกนอกสถานที่เกิน 3 นาที)',
    multiDropDescLeaveLate: 'ออกจุดสาย',
    multiDropDescLeaveLateDesc: 'พาหนะออกจุดหมายล่าช้ากว่าเวลาแผน',
    multiDropDescLeaveOnTime: 'ออกจุด',
    multiDropDescLeaveOnTimeDesc: 'พาหนะออกจุดหมาย (ตรงเวลา)',
    materialPickUpPoint: 'จุดรับวัสดุ',
    moreInformation: 'ข้อมูลเพิ่มเติม',
    multidrop: 'Multidrop',
    multidropKey: 'Multidrop Key',
    materialCodeIsRequired: 'ระบุ รหัสวัตถุดิบ',
    mode: 'โหมด',
    monitorGPS: 'ติดตาม %GPS',
    monitorGPSLog: 'รายการสถานะ GPS',
    move_during_day: 'ย้ายระหว่างวัน',
    move_endof_day: 'ย้ายเมื่อสิ้นวัน',
  },
  n: {
    notSpecified: 'ไม่ได้ระบุ',
    notiDownloadVideo:
      'สามารถ download video ย้อนหลังได้ไม่เกิน 3 วัน สำหรับรถที่วิ่งงานปกติ',
    notApprove: 'ปฏิเสธ',
    NewSitePoint: 'ตำแหน่งใหม่ของหน่วยงาน (Lat,Long)',
    numberOfTimesRunningOverSpeed: 'จำนวนครั้งที่ความเร็วเกิน',
    notifyVehicleRegistration: 'แจ้งเตือน รถทะเบียน',
    nearestPlant: 'แพลนต์ปูนที่ใกล้ที่สุด',
    notInstallGPS: 'ไม่มี GPS',
    neighborhoodInformation: 'ข้อมูลพื้นที่ใกล้เคียง',
    notFinished: 'ดำเนินการยังไม่เสร็จ',
    notFoundIdCard: 'ไม่พบเลขบัตรประชาชน',
    noDriverName: 'ไม่มีพนักงานขับขี่',
    numberSpeedsExceeded: 'จำนวนความเร็วเกินกำหนด',
    noTruckInWrongSpot: 'ไม่มีรถโม่เข้าผิดจุด/เทปูนนอกจุด',
    number: 'จำนวน',
    numberOfTruckIsReadyToUse: 'จำนวนรถโม่พร้อมใช้งาน',
    numberOfTruckIsWaitingForProduction: 'จำนวนรถโม่จอดรอผลิต',
    numberOfTruckOnTheWayAndOnSite: 'จำนวนรถโม่กำลังเดินทางและอยู่หน้างาน',
    numberOfTruckGoesBack: 'จำนวนรถโม่เดินทางกลับ',
    name: 'ชื่อ',
    nameAE: 'ชื่อ AE',
    nameAndType: 'ชื่อและประเภท',
    nameTableCompany: 'ชื่อ',
    nameTitle: 'คำนำหน้า',
    nationality: 'สัญชาติ',
    nationalityZone: 'โซนสัญชาติ',
    nearByLocation: 'สถานที่ใกล้เคียง',
    nearbyPlants: 'เเพลนต์ใกล้สุด',
    nearPlants: 'แพลนต์ใกล้ๆ',
    netWeight: 'น้ำหนักสุทธิ',
    netWeightTon: 'น้ำหนักสุทธิ',
    new: 'เพิ่ม',
    newCustomer: 'ผู้ใช้บริการใหม่',
    newJob: 'งานใหม่',
    newLocation: 'สถานที่ใหม่',
    newPassword: 'รหัสผ่านใหม่',
    newRoute: 'เส้นทางการวิ่งใหม่',
    newTicket: 'Ticket ใหม่',
    newUser: 'ผู้ใช้งานใหม่',
    newUserGroup: 'กลุ่มผู้ใช้บริการใหม่',
    next: 'ถัดไป',
    nextMaintenanceConditions: 'เงื่อนไขการซ่อมบำรุงครั้งต่อไป',
    nextQueue: 'คิวถัดไป',
    nextRegisterDate: 'วันต่ออายุประกันภัยพาหนะ',
    nightTime: 'ช่วงเวลากลางคืน',
    no: '#',
    no_filter: 'ไม่กรอง',
    noData: 'ไม่มีข้อมูล',
    node: 'Node',
    nodeName: 'ชื่อ Node',
    noEntry: 'ห้ามเข้า',
    noExit: 'ห้ามออก',
    nofilevideo: 'ไม่สามารถสตรีมวิดีโอในเวลาที่เลือกได้',
    none: 'ปกติ',
    noParking: 'จุดห้ามจอด',
    normalStatusImage: 'รูปภาพแสดงสถานะปกติ',
    noStatus: 'ไม่มีสถานะ',
    notAllowParking: 'จอดในพื้นที่',
    notAvailable: 'ไม่พร้อมใช้งาน',
    note: 'หมายเหตุ',
    noteFill: 'ระบุหมายเหตุ',
    notification: 'การแจ้งเตือน',
    notificationEndWhen: 'จบแจ้งเตือนเมื่อ',
    notificationPasswordNotSafeInfo:
      'แจ้งเตือนรหัสผ่านไม่ปลอดภัย ผู้ใช้สามารถเปลี่ยนรหัสผ่านด้วยตนเองที่เมนูโปรไฟล์',
    notificationTime: 'เวลาที่แจ้งเตือน',
    notificationTracking: 'การแจ้งเตือน ติดตามพาหนะ',
    notificationType: 'ประเภทการเเจ้งเตือน',
    notificationTypeEvent: 'ประเภทการเเจ้งเตือน (Event Type)',
    notReadyForWork: 'ไม่พร้อมรับงาน',
    notReady: 'ไม่พร้อม',
    notYetShipped: 'ยังไม่จัดส่ง',
    nounsCar: 'คัน',
    noVehicleSelect: 'ยังไม่เลือกพาหนะ',
    now: 'ปัจจุบัน',
    number: 'จำนวน',
    number_of_days: 'จำนวนวัน',
    numberOfAlarmsPerDay: 'จำนวนครั้งที่เกิด Alarm ต่อวัน',
    numberOfCardSwipeTimes: 'จำนวนครั้งที่รูดบัตร',
    numberOfContinuousAlarmDay: 'จำนวนวันที่เกิด Alarm ต่อเนื่อง',
    NumberofdaysworkedReport: 'จำนวนวันที่พาหนะวิ่งงาน',
    numberofEntryLatePoint: 'จำนวนจุดที่เข้าช้า (จุด)',
    numberOfExpirationDates: 'จำนวนวันที่หมดอายุ',
    numberOfVisitors: 'จำนวนผู้เข้าชม',
    numberVehiclegroup: 'จำนวน (คัน)',
    notpay: 'ไม่ต้องชำระ',
    numberOfTimes: 'จำนวน (ครั้ง)',
    noReport: 'ลำดับ',
    numberOfTrips: 'จำนวนเที่ยว',
    notify_type: 'เหตุการณ์แจ้งเตือน',
    notify_type_require: 'กรุณาเลือกเหตุการณ์แจ้งเตือน',
    netPayload: 'น้ำหนักบรรทุกสุทธิ',
    netPayloadIsRequire: 'ระบุน้ำหนักบรรทุกสุทธิ',
    newDestinationLocation: 'ผู้รับใหม่',
    newTicketNumber: 'ตั๋ว (ใหม่)',
    notSubmittingWork: 'ไม่ส่งงาน',
    noQueuesOrReservations: 'ไม่มีการรับคิวหรือจองคิว',
    notifyTheTruckInCorrectPoint: 'เเจ้งเตือนเข้าผิดหน่วยงาน',
    nameComparativeRawMaterials: 'ชื่อ วัตถุดิบเทียบ',
    newItemsOnly: 'รายการใหม่เท่านั้น',
    noProblemWeCanDoItItForYouButWeNeedToClearYourTicketGroupFirst:
      'ไม่มีปัญหา! เราจัดการให้คุณได้ แต่เราต้องเคลียร์กลุ่มตั๋วของคุณก่อน',
    numberOfBookings: 'จำนวนงาน',
    numberOfVehicles: 'จำนวนรถ',
    numberOfVehiclesIsReadyToUse: 'จำนวนรถที่พร้อมรับงาน',
  },
  o: {
    OriginSitePoint: 'ตำแหน่งเดิมของหน่วยงาน (Lat,Long)',
    orderSize: 'ขนาดงาน',
    oilThereIsMalfunction: 'น้ำมัน (มีความผิดปกติ)',
    oilNotYetCalibrated: 'น้ำมัน (ยังไม่ถูก Calibrate)',
    oilAbnormalityWarning: 'แจ้งเตือนความผิดปกติของน้ำมัน',
    orderOfEvents: 'ลําดับเหตุการณ์',
    ocher: 'ดินเหลือง',
    OnlyMaximum1000RowsDataCanBeImportedAtTime:
      'นำเข้าข้อมูลได้ไม่เกินครั้งละ 1,000 เเถวเท่านั้น',
    openTime: 'เปิดเครื่องยนต์ (วัน-เวลา)',
    on_time: 'ตรงเวลา',
    ontime: 'ถึงไซต์งานตรงเวลา',
    oilPriceB10: 'ราคาน้ำมัน (B10)',
    oilConsumptionRate: 'อัตราการบริโภคน้ำมัน',
    oilUsage: 'ใช้น้ำมันไป',
    oilUsageDetails: 'รายละเอียดการใช้น้ำมัน',
    outsidePoint: 'เทปูนนอกจุด',
    outsidePointNumber: 'เทปูนนอกจุดครั้งที่',
    obd: 'OBD',
    off: 'ดับ',
    offFilter: 'ดับ',
    offFilterMeter: 'ปิด',
    offline: 'ออฟไลน์',
    ok: 'ตกลง',
    okay: 'ตกลง',
    on: 'ติด',
    onDevelop: 'อยู่ระหว่างการพัฒนา',
    onFilter: 'ติด',
    onFilterMeter: 'เปิด',
    ongoing: 'กำลังจัดส่ง',
    online: 'ออนไลน์',
    onlineonly: 'สถานะออนไลน์เท่านั้น',
    onProcess: 'อยู่ระหว่างการส่ง',
    onProcessing: 'กำลังดำเนินการ',
    OnSite: 'หน้างาน',
    open: 'เปิด',
    opened: 'รับเรื่อง',
    openFilter: 'เปิดการค้นหา',
    operation: 'การทำงาน',
    operator: 'ผู้ประกอบการ',
    or: 'หรือ',
    orderCode: 'รหัสการสั่งซื้อ',
    ordinal_numbers: 'ครั้งที่',
    origin: 'ต้นทาง',
    originLocation: 'ต้นทาง',
    originToDestination: 'ต้นทาง-ปลายทาง',
    other: 'อื่นๆ',
    outOfFactory: 'ออกจากโรงาน',
    outOfOriginlocation: 'ออกจากต้นทาง',
    outside: 'ด้านนอก',
    over_hour: 'พาหนะวิ่งเกิน(ชั่วโมง)',
    over12Month: 'ช่วงวันที่ที่เลือกไม่ควรเกิน 12 เดือน',
    over20: 'เลือกรถได้ไม่เกิน 20 คัน',
    over15: 'เลือกรถได้ไม่เกิน 15 คัน',
    over1Month: 'ช่วงวันที่ที่เลือกไม่ควรเกิน 31 วัน',
    over7Day: 'ช่วงวันที่ที่เลือกไม่ควรเกิน 7 วัน',
    over2Month: 'ช่วงวันที่ที่เลือกไม่ควรเกิน 2 เดือน',
    over30: 'เลือกรถได้ไม่เกิน 30 คัน',
    over32Month: 'ช่วงวันที่ที่เลือกไม่ควรเกิน 3 ปี',
    over1day: 'ช่วงวันที่ที่เลือกไม่ควรเกิน 1 วัน',
    over50: 'เลือกรถได้ไม่เกิน 50 คัน',
    over100: 'เลือกรถได้ไม่เกิน 100 คัน',
    over2000: 'เลือกรถได้ไม่เกิน 2000 คัน',
    over3Days: 'เกิน 3 วัน',
    overdueSpeedInTheCommunityArea: 'ความเร็วเกินกำหนดในเขตชุมชน',
    overOneMonth: 'ช่วงวันที่ที่เลือกไม่ควรเกิน 1 เดือน',
    overSpeedInNormalRoutes: 'ความเร็วเกินกำหนดในเส้นทางปกติ',
    overSpeedInTheJunction: 'ความเร็วเกินกำหนดในทางเเยก',
    overview: 'ภาพรวม',
    overviewNotificationTypeHeatMap: 'ภาพรวมประเภทการแจ้งเตือน (Heat Map)',
    ownerView: 'เจ้าของพาหนะเห็นข้อมูล',
    vehicleOffline: 'พาหนะสถานะออฟไลน์',
    other: 'อื่นๆ',
    onTheMove: 'รถที่วิ่งงาน',
    outOfTimeLimit: 'จัดส่งไม่ตรงเวลา',
    over1Day: 'ช่วงวันที่ที่เลือกไม่เกิน 1 วัน',
    otherRawMaterialCodes: 'รหัส วัตถุดิบอื่นๆ',
    only_dp: 'ออกเฉพาะที่มี DP',
    only_dps: 'เฉพาะที่มี DP',
    open: 'เปิด',
    openSettings: 'เปิดการตั้งค่า',
    openATicketAtTheCpacSystem: 'เปิดตั๋วที่ระบบ CPAC',
    orderNo: 'หมายเลขงาน',
    order: 'งาน',
  },
  p: {
    pleaseDoubleCheck: 'โปรดตรวจสอบข้อมูลของท่านอีกครั้งก่อนกด "ยืนยัน"',
    pleaseZoomInToSearch: 'กรุณา zoom-in เพื่อค้นหา',
    pleaseFillYourRouteName: 'โปรดระบุชื่อเส้นทางของท่าน',
    pleaseFillRouteName: 'กรุณาตั้งชื่อเส้นทาง',
    pouringConcreteOutSideThePointList: 'รายการเทปูนนอกจุด',
    plantName: 'โรงงานที่ punch in',
    pinDoesNotExistInSystem: 'ปักหมุดหน่วยงานที่ไม่มีในระบบ',
    precastConcrete: 'คอนกรีตสำเร็จรูป',
    production: 'ยอดผลิต',
    percentage_working: '% การทำงาน',
    percentage_working_day: 'ประสิทธิภาพ GPS(%)',
    primary_location: 'โรงงานที่สังกัด',
    parkingOnRoadSide: 'จุดนอกจุดจอด',
    pendingdistrubutetask: 'รอแจกจ่ายงาน',
    pendingconfirmation: 'รอยืนยัน',
    parking: 'จอดพาหนะ',
    parkingDistance: 'ระยะทาง (กิโลเมตร)',
    parkingDuration: 'ระยะเวลาที่จอดรถ (ชั่วโมง:นาที:วินาที)',
    parkingEngineOn: 'เวลาที่จอดติดเครื่อง',
    parkingLocation: 'สถานที่จอดรถ',
    parkingLot: 'ลานจอดพาหนะ',
    parkingOnTheRoadShoulder: 'จุดนอกจุดจอด',
    ParkingReport: 'จุดจอดพาหนะ',
    parkingSumDistance: 'ระยะทางสะสม (กิโลเมตร)',
    parkingTime: 'เวลาที่จอดรถ',
    parkingDateTime: 'วันเวลาที่จอด',
    part_type: 'อะไหล่พาหนะ',
    partgroup: 'กลุ่มอะไหล่พาหนะ',
    partiallyDamagedCard: 'บัตรเสียหายบางส่วน',
    partner: 'ผู้เช่าพาหนะ',
    partners: 'พาร์ทเนอร์',
    partnerCar: 'ผู้เช่าพาหนะ',
    partnerPerformReport: 'สรุปเรียกเก็บเงินตามผู้รับเหมา',
    partSetting: 'ตั่งค่าอะไหล่',
    partType: 'ชนิดอะไหล่พาหนะ',
    passenger: 'จำนวนผู้โดยสาร',
    password: 'รหัสผ่าน',
    passwordMustBeAtLeast6: 'รหัสผ่านอย่างน้อย 6 ตัวอักษร',
    passwordMustMatch: 'ยืนยันรหัสผ่านใหม่ไม่ตรง',
    passwordNotSafeValidateText: 'รหัสผ่านไม่ปลอดภัย',
    passwordsMustMatch: 'รหัสผ่านต้องเหมือนกัน',
    past7day: 'ย้อนหลัง 7 วัน',
    payPoint: 'จุดจ่าย',
    pdf: 'PDF',
    pdfFilesUpTo5MBInSize: 'ไฟล์ PDF ขนาดไม่เกิน 5MB',
    pending: 'รอดำเนินการ',
    period: 'ระยะเวลา',
    periodOfInsuranceYear: 'ระยะเวลาประกันภัยพาหนะ (ปี)',
    permission: 'สิทธิ์',
    personal: 'บุคคล',
    personalID: 'หมายเลขบัตรประชาชน',
    personalIDMustBeNumber: 'หมายเลขบัตรประจำตัวประชาชนต้องเป็นตัวเลข',
    personalIDTooLong: 'หมายเลขบัตรประจำตัวประชาชนต้องมี 13 ตัว!!!',
    personalIDTooShort: 'หมายเลขบัตรประจำตัวประชาชนต้องมี 13 ตัว!!!',
    personalInformation: 'ข้อมูลส่วนบุคคล',
    phoneNumber: 'เบอร์โทร 1',
    phoneNumberMustBeNumber: ' ต้องเป็นตัวเลขและอยู่ระหว่าง 8-20 ตัวอักษร',
    photo: 'รูป',
    pickup: 'จุดรับ',
    pickupData: 'วันนัด',
    pickUpDateTime: 'วัน/เวลานัด',
    pickUpDetail: 'รายละเอียดจุดรับ',
    pickUpLocation: 'สถานที่รับ',
    pickUpTheQueue: 'รับคิว',
    pickUpTime: 'เวลานัด',
    picture: 'รูปภาพ',
    pictureUpdate: 'อัพเดทรูปภาพ',
    pinDown: 'ปักหมุด',
    pinDownGG: 'ปักหมุด Google street view',
    plan_start_deliver_at: 'เวลาเริ่มจัดส่งตามแผน',
    plan: 'ตามแผน',
    plant: 'เเพลนท์',
    plantConcrete: 'แพลนต์ปูน',
    plants: 'โรงงาน',
    plantsDelivery: 'โรงงานจัดส่ง',
    plant_name: 'ชื่อแพลนต์',
    plantArrivingTime: 'เวลาเข้าแพลนต์',
    plantCode: 'รหัสแพลนต์',
    plantFactory: 'Plant/Factory',
    plantMiningContract: 'สัญญาการขนส่ง',
    plantWasNotFound: 'ไม่พบเเพลนต์ในระบบ',
    plateNo: 'ทะเบียน',
    plateNoCar: 'ทะเบียนรถ',
    plateNoOrCode: 'ทะเบียน/รหัสพาหนะ',
    plateNoOfInstall: 'ทะเบียนรถที่ติดตั้ง',
    plateNoOfUninstall: 'ทะเบียนรถที่แจ้งถอด',
    plateNoVehicleCode: 'ทะเบียน, รหัสพาหนะ',
    plateNoVehicleCodeOnline: 'ทะเบียน, รหัสพาหนะ(สถานะออนไลน์เท่านั้น)',
    PleaseEnterInitialStartTime: 'กรุณากรอกเวลาติดเครื่องเริ่มต้น',
    PleaseEnterInitialEndTime: 'กรุณากรอกเวลาติดเครื่องสิ้นสุด',
    pleaseChooseDateRange: ' กรุณาเลือกช่วงวันที่',
    pleaseChooseEvent: 'กรุณาเลือกเหตุการณ์',
    pleaseChooseReport: 'กรุณาเลือกรายงาน',
    pleaseChooseVehicle: ' กรุณาเลือกรถ',
    ChooseVehicle: 'เลือกรถ',
    pleaseEnterEmailAddress: 'กรุณากรอกที่อยู่อีเมล',
    PleaseFillVehicleFareHours: 'กรุณากรอกค่ารถวิ่งเกิน(ชั่วโมง)',
    pleaseEnterEmailSubject: 'กรุณากรอกหัวข้ออีเมล',
    pleaseSelect: 'กรุณาเลือก',
    pleaseSelectDriver: 'กรุณาเลือกพนักงานขับขี่',
    pleaseSelectExpirationDate: 'กรุณาเลือกจำนวนวันหมดอายุ',
    pleaseSelectLocation: 'กรุณาเลือกสถานที่',
    pleaseSelectPicture: 'กรุณาเลือกรูปภาพ',
    pleaseSelectReportType: 'กรุณาเลือกรายงาน',
    pleaseSelectTheReport: 'กรุณาเลือกรายงาน',
    pleaseSelectVehicleCode: 'กรุณาเลือกรหัสพาหนะ',
    pleaseSelectVehicleRegistration: 'กรุณาเลือกทะเบียนพาหนะ',
    pleaseSpecifyDeliveryTime: 'โปรดระบุเวลาส่งของ',
    pleaseSpecifyDeliveryTimeAndWork: 'โปรดระบุเวลาส่งของเเละงาน',
    pleaseSpecifyTheNumberOfVehiclesNotMoreThanTheNumberOfTasks:
      'โปรดระบุจำนวนรถให้ไม่เกินจำนวนของงาน',
    pleaseSpecifyVehicle: 'โปรดระบุพาหนะ',
    PleaseSelectStartingPoint: 'กรุณาเลือกจุดเริ่มต้น',
    PleaseSelectEndPoint: 'กรุณาเลือกจุดสิ้นสุด',
    pleaseSpecifyTicket: 'โปรดระบุตั๋ว',
    pleaseUploadACarRegistrationBookFileForGPSVendor:
      'โปรดอัพโหลดไฟล์ หน้าเล่มทะเบียนรถ เพื่อเป็นข้อมูลสำหรับผู้ให้บริการ GPS',
    po: 'ใบสั่งซื้อ',
    point: 'คะแนน',
    policy: 'นโยบาย',
    policy_report_menu: 'รายงาน นโยบาย',
    polyGonRadius: 'รัศมี',
    PONumber: 'หมายเลขใบสั่งซื้อ',
    poNumberIsrequired: ' กรุณากรอกหมายเลขใบสั่งซื้อ',
    position: 'ตำแหน่ง',
    precastDashboard: 'สถานะขนส่งพื้น-ผนัง',
    preventReDrawLocationConfirm:
      'ตำแหน่งสถานที่จะหายไปเมื่อกดปักหมุดใหม่ ต้องการยืนยัน?',
    preventRePinLocationConfirm:
      'ตำแหน่งสถานที่จะหายไปเมื่อกดปักหมุดใหม่ ต้องการยืนยัน?',
    preview: 'แสดงตัวอย่าง',
    previous: 'ย้อนกลับ',
    primary_location: 'แพลนต์ที่สังกัด',
    privilege: 'กลุ่มสิทธิ์',
    privilegegroup: 'กลุ่มสิทธิ์',
    processing: 'กำลังประมวลผล',
    product: 'สินค้า',
    product_code: 'รหัสสินค้า',
    product_code_duplicate_require: 'รหัสสินค้านี้มีอยู่แล้วในระบบ',
    product_code_require: 'กรุณากรอกรหัสสินค้า',
    product_description: 'รายละเอียด',
    product_name: 'ชื่อสินค้า',
    product_name_duplicate_require: 'ชื่อสินค้านี้มีอยู่แล้วในระบบ',
    product_name_require: 'กรุณากรอกชื่อสินค้า',
    product_type: 'ชนิดของผลิตภัณฑ์',
    product_uom_require: 'กรุณาเลือกหน่วยนับเล็กสุด',
    productType: 'ประเภทสินค้า',
    profile: 'โปรไฟล์',
    projectName: 'ชื่อโครงการ',
    province: 'จังหวัด',
    pto: 'PTO',
    publicKey: 'รหัสบริษัท',
    pullvehiclesink: 'ดึงรถจม',
    purchaseChannel: 'ช่องทางการซื้อ',
    patternThatAcceptsWeightData: 'รูปแบบการรับน้ำหนัก',
    pending: 'รอยืนยัน',
    problem: 'พบปัญหาการจัดส่ง',
    pay: 'ชำระ',
    performance: 'ประสิทธิภาพ',
    phonenumberisNotValid: 'เบอร์โทรไม่ถูกต้อง',
    phonenumberIsRequired: 'ระบุเบอร์โทร',
    pleaseEnterAnEmailToReceiveTheCertificate:
      'กรุณาระบุ email เพื่อรับหนังสือรับรอง',
    pleaseSpecifyTheNumberOfWorksNotExceedingTheNumberOfCars:
      'โปรดระบุจำนวนงานให้ไม่เกินจำนวนของรถ',
    pinCode: 'PIN Code',
    pleaseProvideCompleteInformation: 'โปรดระบุข้อมูลให้ครบ',
    pleaseSelectWorksheetMaintenance:
      'กรุณาเลือกใบงานแจ้งซ่อมที่ต้องการเปลี่ยนสถานะ',
    pleaseProvideCompleteInformationInTheFile: 'โปรดระบุข้อมูลในไฟล์ให้ครบถ้วน',
    perTrip: '/เที่ยว',
    problemType: 'ประเภทปัญหา',
    perDay: '/วัน',
    pendingApproval: 'รออนุมัติ',
    pickUpPoint: 'ตำเเหน่งรับคิว',
    positionForPhotographingSeal: 'ตำแหน่งถ่ายรูปคลุมผ้าใบ',
    photoLocationDuringDelivery: 'ตำเเหน่งถ่ายรูปส่งของ',
    photoShoot: 'รูปเเบบการถ่ายภาพ',
    previousLocation: 'สถานที่ก่อนหน้า',
    previousTicket: 'ตั๋วก่อนหน้า',
    provideCompleteInformationOfRawMaterialsForComparison:
      'ระบุ ข้อมูลวัตถุดิบเทียบให้ครบถ้วน',
    produceLocation: 'ผลิตภัณฑ์',
    primarily: 'ประจำ',
    phone: 'เบอร์โทรศัพท์',
    perTrip: '/เที่ยว',
    pleaseSpecifyNodeName: 'ระบุชื่อ Node',
    pleaseSpecifyVisibility: 'โปรดระบุ การมองเห็นข้อมูล',
    pleaseSpecifyCompany: 'โปรดระบุบริษัท',
    pleaseSelectFile: 'กรุณาระบุไฟล์',
    pleaseSetUpCompany: 'โปรดตั้งค่าบริษัท',
    postalCode: 'รหัสไปรษณีย์',
    pleaseSpecifySampleImageName: 'โปรดระบุชื่อภาพตัวอย่าง',
    plateNoIsRequired: 'ระบุ ทะเบียน',
    payment: 'การชำระเงิน',
    paymentType: 'ประเภทการชำระเงิน',
    periodIsrequired: 'กรุณาระบุ ช่วงเวลา',
    pause: 'หยุดชั่วคราว',
    pleaseProvideCompleteInformation: 'ระบุ ข้อมูลให้ครบถ้วน',
    pleaseSpecifyDestination: 'กรุณาระบุปลายทาง',
    pleaseSpecifyDate: 'กรุณาระบุวันที่',
    pleaseSpecifyDeliveryTime: 'กรุณาระบุเวลาส่งของ',
    produce_end_time_cal: 'ปล่อยลงรถเสร็จ',
    permissionName: 'ชื่อสิทธิ์',
  },
  product_types: {
    animal_food: 'อาหารสัตว์',
    bag_cement: 'ถุงปูนซีเมนต์',
    bulk_cement: 'ปูนซีเมนต์ผง',
    chill: 'อาหารแช่เย็น',
    concrete_pump: 'ปั๊มคอนกรีต',
    container: 'ขนส่งด้วยตู้คอนเทนเนอร์',
    fcr: 'FCR',
    frozen: 'อาหารแช่แข็ง',
    generic: 'ส่งของทั่วไป',
    plate: 'Plate',
    readymixconcrete: 'คอนกรีตผสมสำเร็จ',
    room_temp: 'ห้องควบคุมอุณหภูมิ',
    spool: 'Spool',
    valuable: 'ของมีค่า',
  },
  q: {
    qty: 'จำนวน',
    quantity: 'ปริมาณ',
    quantityIsRequired: 'กรุณาระบุ ปริมาณ',
    quantity_liter: 'ปริมาณ (ลิตร)',
    quantity_tons: 'ปริมาณ (ตัน)',
    quarter: 'Quarter',
    queueQuantity: 'ปริมาณคิว',
    queue: 'คิว',
    queueTotal: 'จำนวนคิว',
    qrCodeType: 'ประเภทการรับค่าน้ำหนัก',
    queueSystemIsNotInUse: 'ไม่ใช้งานระบบคิว',
    quantityPerTrip: 'ปริมาณต่อเที่ยว',
    quantityPerDay: 'ปริมาณต่อวัน',
  },
  r: {
    readyToUseStatus: 'สถานะพร้อมใช้',
    requestToUseVehicle: 'Truck sharing',
    requestStatus: 'สถานะการร้องขอ',
    ready: 'พร้อม',
    reject: 'ปฏิเสธ',
    requestedNames: 'ชื่อผู้ขอ',
    requestedPhoneNumbers: 'เบอร์ติดต่อผู้ขอ',
    requestedFactory: 'โรงงานที่ร้องขอ',
    requestedName: 'ชื่อผู้ร้องขอ',
    requestedPhoneNumber: 'เบอร์โทรผู้ร้องขอ',
    roundTripDistance: 'ระยะทาง & เวลา',
    redundantEvents: 'มีเหตุการณ์เดิมซ้ำๆ',
    ready_time_for_the_next_job: 'เวลาที่พร้อมรับงานครั้งถัดไป',
    return_time: 'ระยะเวลาเดินทางกลับ (ชั่วโมง)',
    remaining_quantity: 'ปริมาณคอนกรีตเหลือ(คิว)',
    refuel_position: 'ตำแหน่งเติมน้ำมัน',
    repairNoticeDate: 'วันเวลาที่แจ้งซ่อม',
    rateFuelUsagePast6MonthsKmLitre:
      'อัตราการใช้น้ำมันย้อนหลัง 6 เดือน กม./ลิตร',
    rail: 'ราง',
    readiness: 'ความพร้อม',
    rack: 'Rack-U',
    radiuns: 'รัศมี',
    radiunsM: 'รัศมี (ม.)',
    rainingTime: 'ช่วงฝนตก',
    reachTheDestination: 'ถึงปลายทาง',
    reachTheOnSite: 'ถึงหน้างาน',
    reActivate: 'เปิดใช้งานอีกครั้ง',
    receive: 'รับเรื่อง',
    receive_complete: 'รับเรื่อง หรือเสร็จเรียบร้อย',
    receive_date: 'วันที่รับเรื่อง',
    received: 'Received',
    receiveDate: 'วันที่ได้รับเเจ้ง',
    receiveTheGoods: 'รับสินค้า',
    refuel: 'เติมเชื้อเพลิง',
    refuelAt: 'เติมเชื้อเพลิงเมื่อ',
    RefuelReport: 'การเติมน้ำมัน',
    reFuelTitle: 'เชื้อเพลิง',
    remainDay: 'คงเหลือ (วัน)',
    remainingDevice: 'อุปกรณ์ที่เหลืออยู่',
    remainingRequest: 'คำขอคงค้าง',
    remark: 'หมายเหตุ',
    remark1: 'หมายเหตุการจอง 1',
    remark2: 'หมายเหตุการจอง 2',
    remarkVendor: 'หมายเหตุ (Vendor)',
    remarkBooking: 'หมายเหตุการจอง',
    remindDate: 'วันแจ้งเตือน',
    reminderData: 'วันแจ้งเตือน',
    reminderTime: 'เวลาแจ้งเตือน',
    renewalDate: 'วันต่ออายุ',
    RepairDevice: 'เเจ้งย้าย',
    repairingReport: 'การแจ้งซ่อม',
    replay: 'เส้นทางเดินทางย้อนหลัง',
    replayDetail: 'รายละเอียดเส้นทางเดินทางย้อนหลัง',
    replayNew: 'เส้นทางเดินทางย้อนหลัง (ใหม่)',
    replayTracking: 'เส้นทางย้อนหลัง',
    report: 'รายงาน',
    report_daily: 'รายวัน',
    report_monthly: 'รายเดือน',
    report_name: 'ชื่อรายงาน',
    report_taxifare: 'รายงานรายรับ',
    report_weekly: 'รายสัปดาห์',
    report_yearly: 'รายปี',
    reportsSortedColumns: 'รายงานเรียงตามคอลัมน์',
    reportAlarmFrequencyClassifiedByDailyDriver:
      'ความถี่ Alarm แยกตามพนักงานขับขี่ รายวัน',
    reportCardSwipeBehaviorBeforeDrivingDaily:
      'พฤติกรรมการรูดบัตรก่อนขับขี่ รายวัน',
    ReportAlarmFrequencyByDriverDaily:
      'รายงานความถี่ alarm แยกตามคนขับรถ รายวัน',
    reportSummarizingTheNumberOfOffSiteParking: 'จำนวนครั้งการจอดรถนอกพื้นที่',
    reportType: 'ประเภทรายงาน',
    requestACertificate: 'ขอใบรับรอง',
    requestList: 'รายการคำขอ',
    requestMaterial: 'รายการสั่งวัตถุดิบ',
    require: 'โปรดระบุ',
    requiredFieldCompany: 'กรุณากรอกชื่อบริษัท',
    requiredFieldConfirmPassword: 'กรุณายืนยันรหัสผ่าน',
    requiredFieldDischargeDenominator: 'กรุณากรอกค่าคำนวณปริมาณในการเท',
    requiredFieldEmail: 'กรุณากรอกอีเมล',
    requiredFieldFinishRefuel: 'กรุณาเลือกวันที่จบการเติมเชื้อเพลิง',
    requiredFieldFirstName: 'กรุณากรอกชื่อจริง',
    requiredFieldFuelTankSize: 'กรุณากรอกความจุถังเชื้อเพลิง',
    requiredFieldLastName: 'กรุณากรอกนามสกุล',
    requiredFieldLimitDrivingHourTime:
      'กรุณากรอกจำกัดระยะเวลาวิ่งงานต่อเนื่อง(นาที)',
    requiredNumberOnly: 'กรอกเลขได้เท่านั้น และต้องไม่เกิน 10 ตัวอักษร',
    requiredFieldNameTitle: 'กรุณากรอกชื่อหัวข้อ',
    requiredFieldPartType: 'กรุณาเลือกชนิดอะไหล่พาหนะ',
    requiredFieldPassword: 'กรุณากรอกรหัสผ่าน',
    requiredFieldPersonalID: 'กรุณากรอกหมายเลขบัตรประจำตัวประชาชน',
    requiredFieldPhoneNumber: 'กรุณากรอกเบอร์โทรศัพท์',
    requiredFieldPlateNo: 'กรุณากรอกทะเบียนรถ',
    requiredFieldQuantity: 'กรุณากรอกปริมาณ(ลิตร)',
    requiredFieldStartRefuel: 'กรุณาเลือกวันที่เติมเชื้อเพลิง',
    requiredFieldTotalPrice: 'กรุณากรอกราคารวม(บาท)',
    requiredFieldUsername: 'กรุณากรอกชื่อผู้ใช้งาน',
    requiredFieldVehicle: 'กรุณาเลือกพาหนะ',
    requiredFieldVehicleCode: 'กรุณากรอกรหัสพาหนะ',
    requiredFieldVehicleGroupName: 'กรุณากรอกชื่อกลุ่มพาหนะ',
    requiredFieldVehicleType: 'กรุณาเลือกประเภทพาหนะ',
    requiredAccountVehicle: 'กรุณาเลือกรถบัญชี',
    requiredCompanyPartner: 'กรุณาเลือกบริษัทผู้รับเหมา',
    resendEmail: 'ส่งอีเมลอีกครั้ง',
    reset: 'รีเซ็ต',
    restArea: 'จุดพักพาหนะ',
    returnDistance: 'วิ่งเปล่า',
    rfid: 'RFID',
    risk: 'เสี่ยง',
    RiskbehaviourReport: 'พฤติกรรมการขับขี่ไม่ปลอดภัย',
    round: 'รอบ',
    route: 'เส้นทางการวิ่ง',
    route_master_name: 'ชื่อแผนการจัดส่ง',
    routemaster: 'แผนการจัดส่ง',
    routemaster_name_duplicate: 'ชื่อแผนการจัดส่งนี้มีอยู่แล้วในระบบ',
    routemaster_name_require: 'กรุณากรอกชื่อแผนการจัดส่ง',
    routeName: 'ชื่อเส้นทาง',
    routeRadius: 'รัศมีของเส้นทาง',
    routeRadiusM: 'รัศมีของเส้นทาง (ม.)',
    rpm: 'RPM',
    running: 'วิ่ง',
    requiredSite: 'กรุณาเลือกไซต์งาน',
    resetPinCode: 'Reset PIN Code',
    rawMaterialCategory: 'กลุ่มประเภทวัตถุดิบ',
    rawMaterialCategoryIsRequest: 'ระบุ กลุ่มประเภทวัตถุดิบ',
    rawMaterialsThatCanBeCarried: 'วัตถุดิบที่สามารถบรรทุกได้',
    requestToChangeDestination: 'รายการ ร้องขอเปลี่ยนผู้รับ',
    requesttoChangeDestination: 'ร้องขอเปลี่ยนผู้รับ',
    recalculateDeliveryArea: 'คำนวนพื้นที่จัดส่งใหม่',
    requestBy: 'ร้องขอโดย',
    reject: 'ไม่อนุมัติ',
    requestAt: 'ร้องขอเมื่อ',
    randomlyWeighedEvery10Ticket: 'สุ่มชั่งน้ำหนักทุก 10 ใบงาน',
    runDNNumbersAutomatically: 'รันเลข DN อัตโนมัติ',
    receive_ticket_at: 'ได้รับตั๋ว',
    rateFuelUsage: 'การใช้น้ำมัน',
  },
  s: {
    selectedEveryMonths: 'ดึงข้อมูลทั้งหมดโดยไม่สนช่วงวันที่',
    second_clean_at: 'พบการเทปูน',
    SiteRadius: 'รัศมีหน่วยงาน (เมตร)',
    start: 'เริ่มต้น',
    summaryVehicleUsage: 'สรุปการใช้พาหนะ',
    statusAndCapacity: 'สถานะ/ความจุ (ใช้/ทั้งหมด)',
    seeAll: 'ดูทั้งหมด',
    searchYourRoute: 'ค้นหาเส้นทางของคุณ',
    showMorePlantInformation: 'แสดงข้อมูลแพลนต์เพิ่มเติม',
    specifyName: 'ระบุชื่อ',
    siteLargeTruck: 'หน่วยงานรถโม่ใหญ่',
    siteSmallTruck: 'หน่วยงานรถโม่เล็ก',
    smallTruckRoute: 'เส้นทางรถโม่เล็ก',
    smallTruckRouteFromGps: 'เส้นทางรถโม่เล็กจาก GPS',
    smallTruckRouteDoNotEnter: 'เส้นทางรถโม่เล็กห้ามเข้า',
    showCpacPlant: 'แสดงแพลนต์',
    slow: 'ช้า',
    startingAtOverSpeedBrakingSuddenlyWhichCausedTheSpeedToDecreaseRapidly:
      'ออกตัวที่ความเร่งเกินกำหนด/เบรกกระทันหัน ที่ทำให้ความเร็วลดลงอย่างรวดเร็ว',
    safetyEventReport: 'รายงานความปลอดภัยพาหนะ',
    smallTruck: 'รถโม่เล็ก',
    smallTrucKAndLargeTruck: 'รถโม่เล็กและรถโม่ใหญ่',
    system: 'ระบบ',
    ShowMarkerInSimpleWay: 'แสดง Marker ในแบบเรียบง่าย',
    skip: 'ข้าม',
    selectTime: 'เวลาที่เลือก',
    selectPlay: 'เวลาที่กำลังเล่น',
    seeall: 'เห็นทั้งหมด',
    sumTotalDistance: 'จำนวนระยะทางที่วิ่ง',
    second: 'วินาที',
    stopTime: 'หยุดเคลื่อนที่ (วัน-เวลา,สถานที่)',
    setType: 'กำหนดประเภท',
    safeDriving: 'ขับขี่ปลอดภัย',
    speed60kmH: '60 กม./ชม.',
    speed80kmH: '80 กม./ชม.',
    speed100kmH: '100 กม./ชม.',
    speed120kmH: '120 กม./ชม.',
    speedOver: 'ความเร็วเกิน',
    siteName: 'ชื่อไซต์',
    siteNameSafetyDashboard: 'หน่วยงานของรถที่กำลังทำงานอยู่ (dp)',
    startPouring: 'เริ่มเท',
    servicecharge: 'ค่าบริการ',
    start_production: 'เริ่มผลิต',
    saveTableSize: 'บันทึกขนาดตาราง',
    section: 'แผนก',
    smartDispatching: 'Truck Route',
    smallMixer: 'โม่เล็ก',
    safety: 'ความปลอดภัย',
    SafetyEventReport: 'ความปลอดภัยพาหนะ',
    safetyDashboard: 'ความปลอดภัย',
    salary: 'เงินเดือน',
    salesChannels: 'ช่องทางการขาย',
    salesMan: 'พนักงานขาย',
    sand: 'ทราย',
    satellite: 'ดาวเทียม',
    save: 'บันทึก',
    saveNext: 'บันทึกเเละถัดไป',
    scan_date_at: 'วันที่อัพโหลด',
    SCCOCarComparisonReport: 'เปรียบเทียบพาหนะของ SCCO',
    Scoresummary: 'สรุปคะแนน',
    schedule_time: 'เวลาแจ้งเตือน',
    schedule_type: 'ชนิดการแจ้งเตือน',
    scheduled: 'นัดช่าง',
    scheduled_day: 'วันแจ้งเตือน',
    schedulereport: 'กำหนดการแจ้งเตือนรายงาน',
    scheduleWork: 'จัดตารางงาน',
    scoreEconomy: 'คะแนนความประหยัด (คะแนน)',
    scorePlanAchievement: 'คะแนนความสำเร็จตามแผน (คะแนน)',
    scoreSafety: 'คะแนนความปลอดภัย (คะแนน)',
    ScoresummaryReport: 'รูปแบบการสรุปคะแนน',
    scoreTotal: 'คะแนนรวม (คะแนน)',
    sealAt: 'เวลาคลุมผ้าใบ',
    sealNO: 'หมายเลขแถบผนึกสินค้า',
    searchNormal: 'ค้นหา',
    search: 'ค้นหา',
    searchDashCameras: 'พิมพ์ค้นหาจากเลขทะเบียนรถ ...',
    searchDirections: 'ค้นหาเส้นทาง',
    searchPlants: 'ค้นหา',
    searchText: 'ค้นหาคำ',
    searchTextFromPlateNo: 'ค้นหาจากเลขทะเบียน ...',
    second_dest: 'จุดสิ้นสุด',
    secondaryPhoneNumber: 'เบอร์โทร 2',
    seeMap: 'ดูเเผนที่',
    seenAll: 'เห็นข้อมูลทั้งหมด',
    select_event: 'เลือกเหตุการณ์',
    selectAssignTo: 'เลือกผู้รับมอบหมาย',
    selectCriticalityLevel: 'เลือกระดับความรุนเเรง',
    selectDate: 'เลือกวันที่',
    selected: 'เลือก',
    selectedDevice: 'อุปกรณ์ที่เลือก',
    selectedVehicleAlready: 'พาหนะที่เลือกไว้',
    selectFile: 'เลือกไฟล์',
    selectNotificationChannel: 'เลือกช่องทางเเจ้งเตือน',
    selectOne: 'เลือก 1 รายการ',
    selectPlateNo: 'เลือกทะเบียน',
    selectPlateNoOrVehicleCode: 'เลือกพาหนะ',
    selectPlateNoOrVehicleCode: 'เลือกทะเบียน/รหัสพาหนะ',
    selectStatus: 'เลือกสถานะ',
    selectTime: 'เลือกเวลา',
    selectVehicle: 'เลือกพาหนะ',
    selectVehicleGroup: 'เลือกกลุ่มพาหนะ',
    send: 'ส่ง',
    send_data_before_delivery: 'ใช้ข้อมูลวันก่อนหน้า',
    send_email: ' ส่งอีเมล',
    sendAsAGroupOfVehicles: 'ส่งเเบบกลุ่มพาหนะ',
    sender: 'ผู้ส่ง',
    sendFailure: 'ส่งผิดพลาด',
    sendRequest: 'ส่งใบคำขอ',
    sendSelectedVehicles: 'ส่งเเบบเลือกพาหนะ',
    sendWork: 'ส่งงาน',
    sentBy: 'ส่งโดย',
    specifyNumberDescription:
      'ระบุจำนวนครั้ง มากกว่าหรือเท่ากับ จำนวนที่เกิด Alarm ต่อวัน ที่ต้องการการไฮไลท์สี',
    sentSuccessfully: 'ส่งเรียบร้อย',
    serviceinstalldevice: 'ใบแจ้งติดตั้ง',
    servicemovedevice: 'ใบเเจ้งย้าย',
    Servicerepairdevice: 'แจ้งซ่อม/ถอดอุปกรณ์',
    setTheRoute: 'กำหนดเส้นทาง',
    setting: 'ตั้งค่า',
    setting_filter: ' ตั้งค่ารายงาน',
    setup: 'ติดตั้ง',
    shaft: 'จำนวนเพลาล้อ',
    sharedAt: 'แชร์เมื่อ',
    shareFrom: 'พาหนะที่ถูกแชร์',
    shareLocation: 'แชร์สถานที่',
    shareTo: 'พาหนะที่แชร์',
    sharpBend: 'โค้งหักศอก',
    ship_to: 'รหัสไซต์งาน',
    site_name: 'ชื่อไซต์งาน',
    shipping: 'กำลังจัดส่ง',
    shippingInfo: 'ข้อมูลการส่งของ',
    shippingInformation: 'ข้อมูลการจัดส่ง',
    shippingRate: 'อัตราค่าแรงคนขับ',
    shipTo: 'ส่งไปยัง',
    shopee: 'ช้อปปี้',
    show: 'เเสดง',
    showAll: 'เเสดงทั้งหมด',
    showTraffic: 'แสดงการจราจร',
    showlocation: 'แสดงสถานที่',
    simNumber: 'หมายเลขซิม',
    site: 'ไซต์',
    siteCode: 'รหัสไซต์',
    sizeAndWeight: 'ขนาดเเละน้ำหนัก',
    slideWrongCardType: 'รูดบัตรผิดประเภท',
    slidingCard: 'รูดบัตร',
    smsNotifyTo: 'ส่ง sms แจ้งเตือนไปที่หมายเลข',
    so: 'SO',
    someparts: 'บางส่วน..',
    sort: 'เรียง',
    sortAmountAsc: 'จำนวนครั้งน้อยไปมาก',
    sortAmountDesc: 'จำนวนครั้งมากไปน้อย',
    sos: 'sos',
    southeastAsia: 'เอเชียตะวันออกเฉียงใต้',
    specialPrivilege: 'สิทธิพิเศษ',
    speed: 'ความเร็ว',
    speedAveg: 'ความเร็วเฉลี่ย (กม./ชม.)',
    speedKmPerHr: 'ความเร็ว (กม./ชม.)',
    speedLimited: 'ความเร็วเกินกำหนด',
    speedOverlimit60: 'ความเร็วเกิน 60 กม/ชม.',
    SpeedNotOver: 'ความเร็วไม่เกิน (กม./ชม.)',
    speedOverLimit: 'ความเร็วเกินกำหนด',
    speedOverLimitTime: 'ความเร็วเกินกำหนด (ครั้ง)',
    speedOverLimitDate: 'ความเร็วเกินกำหนด (เวลา)',
    speedOverLimitByDriver: 'ความเร็วเกินกำหนดตามพนักงานขับขี่',
    speedOverLimitByVehicleGroup: 'ความเร็วเกินกำหนดตามกลุ่มพาหนะ',
    speedOverLimitByVehicleType: 'ความเร็วเกินกำหนดตามประเภทพาหนะ',
    SpeedoverlimitsummaryReport: 'สรุปความเร็วเกินกำหนด',
    speedRange: 'ช่วงความเร็ว (กม./ชม.)',
    speedRangeNoKm: 'ช่วงความเร็ว',
    start_daily_time: 'เวลาเริ่มในแต่ละวัน',
    start_deliver_at: 'เวลาเริ่มทริป',
    startAndEndValidDateDesc: 'ช่วงวันที่อนุญาตให้ระบบนำข้อมูลพาหนะไปใช้งาน',
    startAt: 'เวลาเริ่ม',
    startAtDrivinglicensescanlog: 'เวลาในการรูดใบขับขี่',
    startDate: 'วันเริ่มต้น',
    startDeliveryAt: 'วัน-เวลาที่เริ่มทริป',
    startDeliveryDay: 'วันที่เริ่มทริป',
    startDestinationCode: 'รหัสต้นทาง',
    startDestinationName: 'ชื่อต้นทาง',
    startEndDate: 'วันที่เริ่มต้น - วันที่สิ้นสุด',
    startLocationCode: 'รหัสต้นทาง',
    startLocationName: 'ชื่อต้นทาง',
    startRefuel: 'เติมเชื้อเพลิงเมื่อ',
    startTrip: 'จุดเริ่มต้นทริป',
    startValidDate: 'วันที่เริ่มใช้พาหนะ',
    state: 'สถานะภาพ',
    status: 'สถานะ',
    statusCurrent: 'สถานะปัจจุบัน',
    status_download_file: 'สถานะการดาวน์โหลดไฟล์',
    status_engineoff: 'ดับเครื่อง',
    status_moving: 'พาหนะวิ่ง',
    status_parked: 'จอดพาหนะ',
    status_vehicle: 'สถานะพาหนะ',
    statusVehicleVideo: 'สถานะ',
    stone: 'หิน',
    stopWithEngineOff: 'จอดดับเครื่อง',
    stopWithEngineOn: 'จอดไม่ดับเครื่อง',
    submit: 'บันทึก',
    submit_name: 'ชื่อผู้ยื่นเรื่อง',
    submit_telno: 'เบอร์ติดต่อผู้ยื่นเรื่อง',
    submitName: 'ชื่อผู้ยื่นเรื่อง',
    submitTelno: 'เบอร์ติดต่อผู้ยื่นเรื่อง',
    subType: 'ประเภทย่อย',
    success: 'สำเร็จ',
    successfullyDelivered: 'จัดส่งเรียบร้อยแล้ว',
    summaryDashboard: 'แดชบอร์ดสรุป',
    SummaryiconsiamReport: 'สรุปรอบการให้บริการเรือ / รถ แบบเฉลี่ยรายวัน',
    summaryInfo: 'สรุปข้อมูล',
    SummaryKPIByDriverReport: 'KPI แยกตามพนักงานขับขี่',
    SummaryKPIReport: 'สรุปรายงาน KPI',
    support: 'สนับสนุน',
    surplus: 'เหลือ',
    salary: 'เงินเดือน',
    sentSuccessfully: 'ส่งเเล้ว',
    searchBy: 'ค้นหาตาม',
    status_repair: 'สถานะการแจ้งซ่อม',
    statusGR: 'สถานะ GR',
    sumOverLimitTime: 'เวลารวมวิ่งเกินพิกัด (ชั่วโมง:นาที:วินาที)',
    setPermissions: 'กำหนดสิทธิ',
    sendInPlantArea: 'ส่งในพื้นที่ปลายทางเท่านั้น',
    seal: 'ซีลยึดคันบังคับดัมซ์',
    sideSeal: 'ซีลข้างรถ',
    stackStockAfterDumpIsDone: 'กองสต็อกหลังดัมซ์เสร็จ',
    shareFrom: 'เเชร์จาก',
    systemKey1: 'กุญเเจเชื่อมระบบ',
    smartScale: 'Smart Scale',
    siteGroup: 'กลุ่มหน่วยงาน',
    site: 'หน่วยงาน',
    siteGroupName: 'ชื่อกลุ่มหน่วยงาน',
    siteGroupNameIsRequired: 'กรุณากรอกชื่อกลุ่มหน่วยงาน',
    supplierNo: 'รหัส ผู้ขายวัตถุดิบ',
    subcontactNo: 'รหัส ผู้ขนส่ง',
    supplierNoRequest: 'ระบุ รหัส ผู้ขายวัตถุดิบ',
    subcontactNoRequest: 'ระบุ รหัส ผู้ขนส่ง',
    scaleBeforeSuckingTheSolution: 'สเกลก่อนดูดน้ำยา',
    scaleAfterSuckingTheSolution: 'สเกลหลังดูดน้ำยา',
    stockPickingPoint: 'กองสต็อกจุดรับ',
    stoneTransportTruckPlate: 'รถขนส่งหินทะเบียน',
    samplingBagWithInformationOnTheFrontOfTheBag:
      'ถุงเก็บตัวอย่างเห็นข้อมูลหน้าถุง',
    sendWrongDestination: 'ส่งผิดปลายทาง',
    setVariable: 'ตั้งค่าตัวเเปร',
    section_code: 'รหัสแผนก',
    sector: 'ภาค',
    secondary: 'สำรอง',
    subdistrict: 'ตำบล',
    sampleImages: 'ภาพตัวอย่าง',
    subMaterialCodeIsRequired: 'ระบุ รหัสวัตถุดิบเทียบ',
    siteCode: 'รหัสไซต์',
    siteCodeIsRequired: 'กรุณาระบุ รหัสไซต์',
    siteInformation: 'ข้อมูล Site งาน',
    siteLocation: 'ตำแหน่ง Site งาน',
    subDistrictSubDistrict: 'แขวง/ตำบล',
    start: 'เริ่ม',
    smallsTruck: 'รถโม่เล็ก',
    saleOrderNo: 'หมายเลขใบสั่งขาย',
    startWork: 'วันที่เริ่มงาน',
    startTime: 'เวลาเริ่มต้น',
    saleOrder: 'กองงาน',
    selectOrder: 'เลือกงาน',
    showOnlyGPSLessThan100: 'แสดงเฉพาะข้อมูลที่น้อยกว่า 100%',
    start_dropping_off_truck: 'เริ่มปล่อยลงรถ',
    structure: 'โครงสร้าง',
    sale_order: 'Sale order',
    subproduct: 'SUBPRODUCT',
    structure: 'ประเภทงานเท',
    secondCreateAt: 'เทปูนครั้งแรก',
    startproduction: 'เริ่มผลิต',
    showEnabledOnly: 'แสดงเฉพาะที่เปิดใช้งาน',
    speedAverage: 'ความเร็วเฉลี่ย',
  },
  scheduleReport: {
    excel: 'Excel',
    pdf: 'PDF',
    theDriverIsIicenseReportHasExpired: 'ทั่วไป >> ใบขับขี่หมดอายุ',
    SpeedoverlimitsummaryReport: 'พฤติกรรม >> สรุปความเร็วเกินกำหนด',
    dailyCarStatusReport: 'พฤติกรรม >> สรุปสถานะพาหนะรายวัน',
    DrivinglicensescanlogReport: 'พฤติกรรม >> การรูดใบขับขี่',
    DrivinglogReport: 'พฤติกรรม >> บันทึกการขับขี่',
    summaryFuelUsage: 'ทั่วไป >> สรุปการใช้พาหนะ',
    distancereport: 'ทริป >> ระยะทางการจัดส่งคอนกรีต',
    gpsDisconnectOnTrip: 'การจัดการอุปกรณ์ >> GPS ขาดการติดต่อขณะวิ่งงาน',
    gpsReportLostContact: 'การจัดการอุปกรณ์ >> GPS ขาดการติดต่อ',
    drivingSummary: 'ประสิทธิภาพ >> ภาพรวมสรุปการขับขี่รายคัน',

    SafetyEventReport: 'Alert เเละ Alarm >> ความปลอดภัยพาหนะ',
    fuelremainrateReport: 'พลังงาน >> น้ำมันคงเหลือ',
    TripbyengineoffonReport:
      'พฤติกรรม >> การเดินทางแยกประเภทตามการเปิด-ปิดเครื่องยนต์',

    BehaviordrivingReport:
      'Alert เเละ Alarm >> สรุปพฤติกรรมการขับรถของ เจ้าหน้าที่บริการและจัดส่ง',
    BehaviordrivingbycompanyReport:
      'Alert เเละ Alarm >> สรุปพฤติกรรมการขับรถของ เจ้าหน้าที่บริการและจัดส่ง แต่ละผู้รับเหมา',

    TruckengineonReport: 'ประสิทธิภาพ >> พาหนะติดเครื่อง',
    VehicleperformanceconcludeReport:
      'ประสิทธิภาพ >> สรุปประสิทธิภาพพาหนะรายคัน',

    FueluseReport: 'พลังงาน >> การคำนวณปริมาณการใช้น้ำมันของพาหนะ',
    report_comparevehiclescco: 'ทั่วไป >> รายงาน เปรียบเทียบพาหนะ SCCO',
    report_dailyiconsiam: 'ทั่วไป >> รายงานการให้บริการเรือ / รถ แบบรายวัน',
    report_distfromlastmaintenance:
      'ทั่วไป >> รายงานพาหนะที่มีระยะทางวิ่งสะสมล่าสุด',
    report_dozeoffrisk: 'ทั่วไป >> รายงานเสี่ยงหลับใน',
    report_driverinformation: 'ทั่วไป >> รายงานข้อมูลพนักงานขับขี่',
    report_drivingdistance: 'ทั่วไป >> รายงานระยะทางการวิ่ง',
    report_drivinghour: 'ทั่วไป >> รายงานพฤติกรรมการขับขี่',
    report_event: 'ทั่วไป >> รายงานเหตุการณ์พาหนะรายคัน',
    report_maintenance: 'ทั่วไป >> รายงานพาหนะที่ต้องบำรุงรักษา',
    report_numberofdaysworked: 'ทั่วไป >> รายงานจำนวนวันที่พาหนะวิ่งงาน',
    report_parking: 'พฤติกรรม >> จุดจอดพาหนะ',
    report_summaryiconsiam:
      'ทั่วไป >> รายงานสรุปรอบการให้บริการเรือ / รถ แบบเฉลี่ยรายวัน',
    report_temperature: 'ทั่วไป >> รายงานอุณหภูมิ',
    report_truckcurrentlocation: 'ทั่วไป >> รายงานที่อยู่ปัจจุบันของพาหนะ',
    report_truckusage: 'ทั่วไป >> รายงานการใช้งานพาหนะ',
    SafetyDashboard: 'แดชบอร์ดความปลอดภัย',
    ScoresummaryReport: 'Alert เเละ Alarm >> รูปแบบการสรุปคะแนน',
    Scoresummary: 'สรุปคะแนน',
    selectVehicle: 'เลือกพาหนะ',
    sendRequest: 'ส่งใบคำขอ',
    serviceinstalldevice: 'ใบแจ้งติดตั้ง',
    sharpBend: 'โค้งหักศอก',
    sizeAndWeight: 'ขนาดเเละน้ำหนัก',
    SpeedNotOver: 'ความเร็วไม่เกิน (กม./ชม.)',
    startAndEndValidDateDesc: 'ช่วงวันที่อนุญาตให้ระบบนำข้อมูลพาหนะไปใช้งาน',
    startValidDate: 'วันที่เริ่มใช้พาหนะ',
    success: 'สำเร็จ',

    report_repairingreport: 'การจัดการอุปกรณ์ >> การแจ้งซ่อม',
    gpsVendorPayingReportCost: 'ค่าใช้จ่าย >> สรุปค่าบริการตาม GPS Vendor',
    gpsVendorPayingSummaryReport: 'ค่าใช้จ่าย >> สรุปค่าบริการ GPS ทั้งหมด',
    partnerPerformReport: 'ค่าใช้จ่าย >> สรุปเรียกเก็บเงินตามผู้รับเหมา',
    NumberofdaysworkedReport: 'ทั่วไป >> จำนวนวันที่พาหนะวิ่งงาน',
    concreteTrip: 'ทริป >> การส่งคอนกรีต',
    DrivingdistanceReport: 'ทั่วไป >> สรุประยะทางการวิ่งประจำวัน',
    TruckusageReport: 'พฤติกรรม >> การใช้งานพาหนะ',
    ParkingReport: 'พฤติกรรม >> จุดจอดพาหนะ',
    repairingReport: 'การจัดการอุปกรณ์ >> การแจ้งซ่อม',
    unauthorizeunload: 'ทริป >> รายงานเทปูนนอกจุด',
    gpsVendorPayingReport: 'ค่าใช้จ่าย >> สรุปค่าบริการตาม GPS Vendor',
  },
  sku: {
    from_uom: 'จากหน่วยนับ',
    from_uom_require: 'กรุณาเลือกจากหน่วยนับ',
    quality: 'จากจำนวน',
    quality_require: 'กรุณากรอกจากจำนวน',
    uom_require: 'กรุณาเลือกหน่วยนับ',
    weight: 'น้ำหนัก',
    weight_require: 'กรุณากรอกน้ำหนัก',
  },
  t: {
    ThisReportHasLimitOnTheNumberOfVehiclesThatCanBeReported:
      'รายงานนี้มีการจำกัดจำนวนรถในการออกรายงาน',
    timeToDriveAtMaximumSpeed: 'เวลาที่ขับเร็วสูงสุด',
    tokenActive: 'ใช้งานได้',
    token: 'โทเค็น',
    tokenExpireds: 'โทเค็นหมดอายุ',
    tooMuchZoom: 'ซูมออกเกินกำหนด',
    timeToArrive: 'เวลาที่จะถึง',
    timeStamp: 'เวลาที่ลงข้อมูล',
    ticketNumber: 'หมายเลขตั๋ว',
    timeMinute: 'เวลา/นาที',
    timeSpitConcrete: 'เวลาคายปูน (ชั่วโมง:นาที)',
    tourStep1: 'ยินดีต้อนรับสู่การเปิดเผยข้อมูล เมนูนี้ประกอบด้วยสองส่วนหลัก',
    tourStep2: 'อย่างแรก ส่วนของต้นไม้',
    tourStep3: 'ประการที่สอง ส่วนรายละเอียด',
    tourStep4: 'นี่คือโหนดรูท',
    tourStep5: 'คลิกเพื่อเพิ่มโหนดย่อย',
    tourStep6: 'โหนดย่อย',
    tourStep7: 'คุณสามารถลากมาที่นี่เพื่อเปลี่ยนเส้นทางของโหนด',
    tourStep8: 'คลิกเพื่อลบโหนดนี้และรายการย่อย',
    tourStep9: 'คลิกเพื่อเพิ่มโหนดย่อย',
    tourStep10: 'คลิกเพื่อเลือกโหนดนี้',
    tourStep11: 'ชื่อของโหนดที่เลือก ซึ่งจะแสดงขึ้นหลังจากที่คุณคลิก',
    tourStep12: 'คุณสามารถเลือกรายละเอียดสำหรับโหนดที่เลือกได้',
    tourStep13: 'คลิกเพื่อบันทึกการเปลี่ยนแปลง',
    totalDividedBy2: 'รวมหาร2',
    totalKm: 'รวม(กม.)',
    take_time_to_clean_the_car: 'ใช้เวลาทำความสะอาดรถ (นาที)',
    ticket_cancellation_code: 'รหัสยกเลิกตั๋ว',
    total_duration: 'ระยะเวลาทั้งหมด (ชั่วโมง)',
    time_to_leave_job_site: 'เวลาออกจากไซต์งาน',
    time_end_cement: 'จบการเทปูนในหน่วยงาน',
    time_start_cement: 'เริ่มเทปูนในหน่วยงาน',
    time_before_start_cement: 'ระยะเวลาก่อนเริ่มเทปูน (ชั่วโมง)',
    time_to_visit_job_site_according_to_plan: 'เวลาเข้าไซต์งานตามเเผน',
    time_to_arrive_job_site: 'เวลาถึงไซต์งาน',
    time_from_plant_to_Site: 'ระยะเวลาจากแพลนต์ถึงไซต์งาน (ชั่วโมง)',
    time_to_leave_plant: 'เวลาออกจากแพลนต์',
    time_to_leave_factory: 'เวลาออกจากโรงงาน',
    time_to_return_plant: 'เวลากลับเข้าแพลนต์',
    truckNumber: 'เบอร์รถ',
    total: 'รวม',
    toSection: 'ถึงหน่วยงาน',
    turnOnTurnOffEngine: 'เปิดปิดเครื่องยนต์',
    truck_id: 'รหัสรถ',
    truck_type: 'ประเภทโม่',
    trip: 'เที่ยว',
    typeoftruck: 'ประเภทรถโม่',
    truckGoesBack: 'จำนวนรถโม่เดินทางกลับ',
    truckOnTheWayAndOnSite: 'รถโม่กำลังเดินทางและอยู่หน้างาน',
    truckIsReadyToUse: 'รถพร้อมใช้งาน',
    truckIsNotReadyToUse: 'รถไม่พร้อมใช้งาน',
    truckStatusDashboardMain: 'สถานะแดชบอร์ด',
    truckstatusdashboard: 'แดชบอร์ดภาพรวมการขนส่งคอนกรีต',
    truck: 'รถโม่',
    tagID: 'รหัสเเท็ก',
    taxiMeter: 'Taxi Meter',
    taxNo: 'Tax No.',
    technician: 'ช่าง',
    telnoAE: 'เบอร์โทรศัพท์ AE',
    temperature: 'อุณหภูมิ',
    temperature1: 'อุณหภูมิ 1',
    temperature2: 'อุณหภูมิ 2',
    TemperatureReport: 'อุณหภูมิ',
    temporaryTracking: 'การติดตามรถชั่วคราว',
    theDayThatTheCarPulled: 'วันที่ดึงพาหนะจม',
    theDriverIsIicenseReportHasExpired: 'ใบขับขี่หมดอายุ',
    theNumberOfWorkCompleted: 'จำนวนงานที่เสร็จสิ้น',
    theRoute: 'เส้นทาง',
    theStatusOfVehiclesThatReceiveDailyJobs: 'การติดตามวัตถุดิบ',
    TheUsualRoute: 'เส้นทางปกติ',
    theWaitingQueue: 'รอเรียกคิว',
    thisItemHasBeenDiscontinued: 'รายการนี้ถูกยกเลิกเเล้ว',
    thisLocationCodeIsAlreadyInTheSystem: 'รหัสสถานที่นี้อยู่ในระบบแล้ว',
    thisMonth: 'เดือนนี้',
    thisNationalIDNumberIsAlreadyInTheSystem:
      'เลขประจำตัวประชาชนนี้ มีอยู่ในระบบแล้ว',
    thisYear: 'ปีนี้',
    ticket: 'ตั๋ว',
    ticketNumber: 'เลขที่ตั๋ว',
    time: 'เวลา',
    time_1440: '24 ชั่วโมง',
    time_15: '15 นาที',
    time_180: '3 ชั่วโมง',
    time_30: '30 นาที',
    time_300: '5 ชั่วโมง',
    time_420: '7 ชั่วโมง',
    time_60: '1 ชั่วโมง',
    time_720: '12 ชั่วโมง',
    time_entry: 'เวลาเข้า',
    time_out: 'เวลาออก',
    time_period: 'ช่วงระยะเวลา',
    time_travel: 'เวลาเดินทาง',
    time_use_in_location: 'เวลาที่อยู่ในสถานี',
    timeGoToLocation: 'เวลาแผนเข้า-ออกสถานที่',
    timeRange: 'ช่วงเวลา',
    timeDate: 'ช่วงวันที่',
    times: 'ครั้ง',
    timeThatCanBeUsed: 'ระยะเวลาที่สามารถใช้งานระบบ',
    timeZone: 'Time Zone',
    tire: 'จำนวนยาง',
    title: 'คำนำหน้า',
    titleAccount: 'บัญชีผู้ใช้',
    to: ' ถึง ',
    to_email: 'ส่งถึง',
    today: 'วันนี้',
    toggleHeatMap: 'Toggle Heat Map',
    tokenExpired: 'เซสชันหมดอายุ',
    tons: 'ตัน',
    tool: 'เครื่องมือ',
    tooLong20: ' ไม่ควรยาวเกิน 20 ตัวอักษร',
    tooLong25: ' ไม่ควรยาวเกิน 25 ตัวอักษร',
    tooLong50: ' ไม่ควรยาวเกิน 50 ตัวอักษร',
    tools: 'เครื่องมือ',
    tooShot8: ' ไม่ควรสั้นกว่า 8 ตัวอักษร',
    top5AlertReportsOfEachTypeDividedByMonthlyTimePeriod:
      'Alert 5 อันดับแรกของแต่ละประเภทแบ่งตามช่วงเวลารายเดือน',
    topCustomer: 'Top Customer',
    topEarning: 'Top Earning',
    toSiteDistance: 'ระยะทางขาไป',
    total: 'ทั้งหมด',
    TotalFuel: 'ปริมาณน้ำมันรวม (ลิตร)',
    totalDistance: 'ระยะทางที่รถวิ่ง (กม.)',
    totalDuration: 'ระยะเวลารวม (วัน:ชั่วโมง:นาที)',
    totalDurationFuelUsage: 'ระยะเวลารวม',
    totalEmergencyCase: 'กรณีฉุกเฉินทั้งหมด',
    totalExpenses: 'ค่าใช้จ่ายทั้งหมด (บาท)',
    totalNumberofMinutesEnteredSlowly: 'จำนวนนาทีเข้าช้ารวม (นาที)',
    totalPrice: 'ราคารวม',
    totalPrice_baht: 'ราคารวม (บาท)',
    totalTime: 'เวลารวม',
    totalTimeHHMM: 'เวลาทั้งหมด (ชม./นาที)',
    tracking: 'ติดตามยานพาหนะ',
    TrackingdataReport: 'รายละเอียดพิกัดพาหนะ',
    trackingNo: 'หมายเลขการติดตาม',
    trainer: 'ผู้ฝึกอบรม',
    trainingDate: 'วันที่อบรม',
    traininglist: 'รายการฝึกอบรม',
    transportationPerformanceDashboard: 'ประสิทธิภาพการขนส่งวัตถุดิบ',
    transportContractor: 'ผรม.ขนส่ง',
    traveling: 'กำลังเดินทาง',
    travelingTooLong: 'เดินทางนานเกินกำหนด',
    trip: 'การขนส่ง',
    trip_menu: 'ทริป',
    trip_date: 'ช่วงเวลาเริ่ม-สิ้นสุดทริป',
    trip_duration: 'ช่วงเวลาเริ่ม-สิ้นสุดทริป',
    trip_report_menu: 'ทริป',
    TripbyengineoffonReport: 'การเดินทางแยกประเภทตามการเปิด-ปิดเครื่องยนต์',
    tripCannotEditDueToOverTime:
      'ไม่สามารถแก้ไขสถานที่ได้เนื่องจากเกินเวลาเริ่มทริปไปแล้ว',
    tripCode: 'รหัสทริป',
    tripCodeDup: 'รหัสทริปมีในระบบแล้ว',
    tripInfo: 'ข้อมูลทริป',
    tripInfoDateTime: 'ข้อมูลทริป วันที่/เวลา',
    tripIsupply: 'เที่ยว',
    tripStartAt: 'เวลาเริ่มทริป',
    tripStatus: 'สถานะทริป',
    TripsumbylocationReport: 'สรุปจำนวนงานตามสถานที่',
    TripsumbyvehReport: 'สรุปการวิ่งแยกตามพาหนะ',
    TripsummaryReport: 'สรุปการวิ่งแยกตามทริป',
    TriptemperatureReport: 'สรุปอุณหภูมิแยกตามทริป',
    truckCategory: 'ประเภทรถบรรทุก',
    TruckcurrentlocationReport: 'ที่อยู่ปัจจุบันของพาหนะ',
    truckDriverMatching: 'จับคู่พนักงานขับขี่',
    TruckengineoffReport: 'พาหนะไม่ติดเครื่อง',
    TruckengineonReport: 'พาหนะติดเครื่อง',
    TrucknotuseReport: 'จำนวนวันที่พาหนะไม่ได้วิ่งงาน',
    truckType: 'ชนิดรถบรรทุก',
    TruckusageReport: 'การใช้งานพาหนะ',
    type: 'ประเภท',
    type_duration: 'ตามช่วงเวลา',
    type_person: 'รายบุคคล',
    type_problem: 'อาการของปัญหา',
    type_report: 'ประเภทรายงาน',
    type_trip: 'รายทริป',
    typeLocation: 'ชนิดของสถานที่',
    typeMaintenance: 'ประเภท',
    truckAvailable: 'รถพร้อมใช้งาน',
    totalFleet: 'กองรถทั้งหมด',
    theCost: 'ค่าใช้จ่าย',
    totalFilesMustBeSmallerThan20MB: 'ไฟล์ทั้งหมดต้องมีขนาดเล็กกว่า 20MB!',
    totalUsageTime: 'รวมเวลาการใช้งาน (วัน:ชั่วโมง:นาที)',
    totalParkingTime: 'รวมเวลาจอดรถ (วัน:ชั่วโมง:นาที)',
    totalIdleTime: 'รวมเวลาเดินเบา (วัน:ชั่วโมง:นาที)',
    totalRunningTime: 'รวมเวลารถวิ่ง (วัน:ชั่วโมง:นาที)',
    totalTimeHHMMSS: 'รวมเวลา (ชั่วโมง:นาที:วินาที)',
    tonsPerTrip: 'จำนวนตัน/เที่ยว',
    thisDoNumberExistsinTheSystem: 'Do Numer นี้มีอยู่ในระบบ',
    tooShort: 'สั้นเกินไป!',
    tooLong: 'ยาวเกินไป!',
    turnLeft: 'เลี้ยวซ้าย',
    turnRight: 'เลี้ยวขวา',
    thereIsaMaterialCodeAlreadyInTheSystem: 'มีรหัสวัตถุดิบนี้แล้วในระบบ',
    timeToWeigh: 'เวลาชั่งน้ำหนัก',
    thisSubMaterialIsAlreadyInTheSystem: 'วัตถุดิบย่อยนี้มีอยู่ในระบบเเล้ว',
    typesOfVehiclesThatCanBeCarried: 'ประเภทพาหนะที่บรรทุกได้',
    typesOfMaterialThatCanBeCarried: 'ประเภทวัตถุดิบที่บรรทุกได้',
    truckTypeno: 'หมายเลขประเภทรถ',
    theDestinationAreaIsNotCovered: 'พื้นที่ปลายทางไม่ครอบคลุม',
    takePhotosOutsideTheArea: 'ถ่ายรูปนอกพื้นที่',
    ticketCreationDate: 'วันที่สร้างตั๋ว',
    theFrontOfTheCarSawTheLicensePlate: 'หน้ารถเห็นป้ายทะเบียน',
    takeAPhotoForward: 'ถ่ายไปข้างหน้า',
    takeAPhotoOnTheRight: 'ถ่ายด้านขวา',
    takeAPhotoNextToTheCar: 'ถ่ายข้างตัวรถ',
    testResult: 'ผล Test',
    takePhotosForWorkOutsideTheArea: 'ถ่ายรูปส่งงานนอกพื้นที่',
    thisIsCorrectAlreadyUsedAt: 'ถูกใช้ที่ใช้ที่ปลายทาง',
    thereIsInformationInTheSystem: 'มีข้อมูลในระบบเเล้ว',
    thisComparativeMaterialCodeAlreadyExistsInTheSystem:
      'รหัสวัตถุดิบเปรียบเทียบนี้มีอยู่แล้วในระบบ',
    thisContractOfCarriageAlreadyInTheSystem:
      'สัญญาการขนส่งนี้ มีอยู่ในระบบแล้ว',
    theVehicleWasNotFoundInTheTERMINUSSystem: 'ไม่พบพาหนะในระบบ TERMINUS',
    truckPickupCard: 'บัตรแข็ง',
    thisCardNumberIsAlreadyInTheSystem: 'มีเลขบัตรนี้แล้วในระบบ',
    totalVehicle: 'รถทั้งหมด',
    telno: 'โทรศัพท์',
    theEndTimeMustBeGreaterThanTheStartTime:
      'เวลาสิ้นสุดต้องมากกว่าเวลาเริ่มต้น',
    typeSite: 'ประเภทไซต์งาน',
    ticketTime: 'เวลาตั๋ว',
    ticketOrder: 'ลำดับตั๋ว',
    ticketPlantName: 'ชื่อโรงงาน',
    truckTimelineDashboard: 'Truck timeline dashboard',
  },
  trip_import: {
    TripCodeAlreadyExistsSystem: 'รหัสทริปนี้มีอยู่แล้วในระบบ',
    confirm_send_require: 'กรุณากรอกยืนยันการส่งโดย(เข้าจุด/ออกจุด)',
    date_arrive_at_require: 'กรุณากรอกวันที่ควรถึงจุดส่งสินค้า/จุดโหลดสินค้า',
    date_out_at_require: 'กรุณากรอกวันที่ควรออกจากจุดส่งสินค้า/จุดโหลดสินค้า',
    date_start_trip_at_require: 'กรุณากรอกวันที่เริ่มทริป',
    do_number_require: 'กรุณากรอกเลขที่ใบส่งสินค้า',
    error_import: 'มีข้อผิดพลาดเกิดขึ้น',
    location_code_not_find:
      'รหัสจุดส่งสินค้า/จุดโหลดสินค้านี้ไม่มีอยู่ในระบบ :',
    location_code_require: 'กรุณากรอกรหัสจุดส่งสินค้า/จุดโหลดสินค้า',
    location_name_require: 'กรุณากรอกชื่อจุดส่งสินค้า/จุดโหลดสินค้า',
    product_code_not_find: 'รหัสสินค้านี้ไม่มีอยู่ในระบบ :',
    product_code_require: 'กรุณากรอกรหัสสินค้า',
    product_name_require: 'กรุณากรอกสินค้า',
    quality_require: 'กรุณากรอกจำนวน',
    sku_not_find: 'หน่วยนับสินค้านี้ไม่มีอยู่ในระบบ :',
    sku_require: 'กรุณากรอกหน่วยนับ',
    time_arrive_at_require: 'กรุณากรอกเวลาที่ควรถึงจุดส่งสินค้า/จุดโหลดสินค้า',
    time_out_at_require: 'กรุณากรอกเวลาที่ควรออกจากจุดส่งสินค้า/จุดโหลดสินค้า',
    time_start_trip_at_require: 'กรุณากรอกเวลาที่เริ่มทริป',
    trip_code_require: 'กรุณากรอกรหัสทริป',
    vehicle_code_not_find: 'รหัสพาหนะนี้ไม่มีอยู่ในระบบ :',
    vehicle_code_not_install: 'รหัสพาหนะนี้ยังไม่ได้ติดตั้งในระบบ :',
    vehicle_code_require: 'กรุณากรอกรหัสพาหนะ',

    date_out_at_format_incorrect:
      'กรุณากรอกวันที่ควรออกจากจุดส่งสินค้า/จุดโหลดสินค้าให้ถูกรูปแบบ :',
    time_out_at_format_incorrect:
      'กรุณากรอกเวลาที่ควรออกจากจุดส่งสินค้า/จุดโหลดสินค้าให้ถูกรูปแบบ :',
    date_start_trip_at_format_incorrect:
      'กรุณากรอกวันที่เริ่มทริปให้ถูกรูปแบบ :',
    time_start_trip_at_format_incorrect:
      'กรุณากรอกเวลาที่เริ่มทริปให้ถูกรูปแบบ :',
    time_arrive_at_format_incorrect:
      'กรุณากรอกเวลาที่ควรถึงจุดส่งสินค้า/จุดโหลดสินค้าให้ถูกรูปแบบ :',
    date_arrive_at_format_incorrect:
      'กรุณากรอกวันที่ควรถึงจุดส่งสินค้า/จุดโหลดสินค้าให้ถูกรูปแบบ :',

    remark_more_than_50: 'หมายเหตุต้องไม่เกิน 50 ตัวอักษร',
    takeAPhotoNextToTheCar: 'take A pPhoto Next To The Car',
  },
  u: {
    unload_date: 'มีการปรับรัศมีหน่วยงานเป็น (เมตร)',
    UnloadPoint: 'ตำแหน่งเทปูน (Lat,Long)',
    useAllFuel: 'ใช้น้ำมันทั้งหมด',
    userThatCreatedTheToken: 'ผู้ใช้งานที่ถูกสร้างโทเค็น',
    underFactory: 'สังกัดโรงงาน',
    underDepartment: 'สังกัดภาค',
    underSection: 'สังกัดแผนก',
    underDivision: 'สังกัดกิจการ',
    unassign: 'Unassign',
    unBroken: 'ไม่เสีย',
    uninstall: 'ถอด',
    unitPrice: 'หน่วยละ (บาท)',
    unknown: 'ไม่ระบุ',
    unLoadCount: 'จำนวนรอบที่เท',
    unloadOutsideTheArea: 'เทนอกพื้นที่',
    unloadOutsideThePourPoint: '',
    unloadPoint: 'จุดเท',
    unloadPointCode: 'รหัสจุดเท',
    unloadPointName: 'ชื่อจุดเท',
    unMatch: 'ยกเลิกการจับคู่',
    uom: 'หน่วยนับ (UOM)',
    uom_description: 'รายละเอียดหน่วยนับ',
    uom_name: 'ชื่อหน่วยนับ',
    uom_name_duplicate_require: 'ชื่อหน่วยนับนี้มีอยู่แล้วในระบบ',
    uom_name_require: 'กรุณากรอกชื่อหน่วยนับ',
    uom_smallest: 'หน่วยนับเล็กสุด',
    updatedAt: 'อัพเดทเมื่อเวลา',
    updated: 'อัพเดท',
    updatedBy: 'เเก้ไขข้อมูลโดย',
    updateLocation: 'อัพเดทตำแหน่ง',
    updateOnlyTheInformationEntered: 'อัปเดตเฉพาะข้อมูลที่ใส่',
    updatePictures: 'อัพเดทรูปภาพ',
    upload: 'Upload',
    uploaderIsRequired: ' กรุณาอัพโหลดไฟล์',
    uploadFile: 'อัพโหลดไฟล์',
    uploadYourFile: 'อัพโหลดไฟล์ของคุณ',
    uploadFileOfRegistrationPage: 'อัพโหลดไฟล์หน้าเล่มทะเบียน',
    unload_finished_at: 'เทปูนครั้งสุดท้าย',
    use_account_name: 'ผู้ใช้งาน',
    use_date_time: 'วันเวลาที่ใช้',
    user: 'ผู้ใช้งาน',
    userClose: 'ผู้ใช้งานปิดเคส',
    userExpire: 'ระยะเวลาสิ้นสุดการใช้งาน',
    userExpireAt: 'ผู้ใช้งานสามารถใช้ระบบได้ถึง',
    userGroup: 'กลุ่มผู้ใช้งาน',
    userGroupPremission: 'กลุ่มผู้ใช้งาน',
    userName: 'ชื่อผู้ใช้งาน',
    userProfile: 'ข้อมูลผู้ใช้',
    updatetSuccess: 'เเก้ไขข้อมูลสำเร็จ',
    updateFail: 'เเก้ไขข้อมูลไม่สำเร็จ',
    unit: 'หน่วย',
    updateimei: 'อัพเดท Imei',
    updatevehicle: 'อัพเดทพาหนะ',
    useTheQueueSystem: 'ใช้งานระบบคิว',
    usernameMustContain: 'ชื่อผู้ใช้ต้องมี',
    unauthorizeunload: 'รายงานเทปูนนอกจุด',
    unload_when_speed_morethan_10: 'เทปูนขณะรถมีความเร็ว > 10 กม./ชม.',
    unload_method: 'วิธีการเท',
    under_plant: 'โรงงานสังกัด',
  },
  v: {
    VehicleNumberVehicleRegistration: 'รหัสพาหนะ/ทะเบียน',
    vehicleStatus: 'สถานะรถ',
    vehicleNumber: 'หมายเลขพาหนะ',
    validDate: 'Valid Date',
    valueIsRequired: 'กรุณากรอกข้อมูลให้ครบถ้วน',
    vehicle: 'พาหนะ',
    vehicle_type: 'ประเภทพาหนะ',
    vehicle_typeIsRequired: 'กรุณาระบุ ประเภทพาหนะ',
    vehicle_type_code: 'รหัสประเภทพาหนะ',
    vehicleAndDriver: 'พาหนะและพนักงานขับขี่',
    vehicleBlacklist: 'พาหนะห้ามเข้าพื้นที่',
    vehicleCertNumber: 'หมายเลขใบอนุญาติขับรถ',
    vehicleCode: 'รหัสพาหนะ',
    vehicleColor: 'สีพาหนะ',
    vehicleDetail: 'ข้อมูลรายละเอียดพาหนะ',
    vehicleGroup: 'กลุ่มพาหนะ',
    vehicleGroupName: 'ชื่อกลุ่มพาหนะ',
    vehicleID: 'รหัสพาหนะ',
    VehicleinareaReport: 'พาหนะเข้าพื้นที่',
    VehicleinformationdltReport: 'ข้อมูลการเดินรถ DLT',
    vehicleModel: 'รุ่นพาหนะ',
    VehicleperformanceconcludeReport: 'สรุปประสิทธิภาพพาหนะรายคัน',
    vehicleRegisterType: 'Vehicle Register Type',
    vehicleRegistrationBookFile: 'หน้าเล่มทะเบียนยานพาหนะ',
    vehicleSharing: 'แชร์พาหนะ',
    vehicleStatus: 'สถานะพาหนะ',
    vehicleType: 'ประเภทพาหนะ',
    VelocitysummaryReport: 'สรุปความเร็วเกิน',
    vendor: 'Vendor',
    vendorIsupply: 'ผู้ขนส่ง',
    verifyYourEmailAddress: 'ยืนยันที่อยู่อีเมลของคุณ',
    version: 'เวอร์ชั่น',
    video: 'วิดีโอเรียลไทม์ MDVR',
    video_type: 'ประเภทวิดีโอ',
    videoDownload: 'ดาวน์โหลดวิดีโอ',
    videospecial: 'MDVR Live Stream (พิเศษ)(วิดีโอเรียลไทม์จาก MDVR)',
    view: 'ดู',
    vnCode: 'รหัสพาหนะ',
    voluntary: 'ประกันภัยรถยนต์ภาคสมัครใจ',
    volumn: 'ปริมาณ',
    vehicle_maintenancedevice_duplicate:
      'พาหนะทะเบียน/รหัสนี้มีการแจ้งซ่อมเข้ามาในระบบแล้ว',
    visibilityOfThisUser: 'สิทธิ์การมองเห็นของผู้ใช้รายนี้',
    visibilityOfThis: 'สิทธิ์การมองเห็นของ',
    vendorCodeIsRequired: 'ระบุ รหัสผู้ขนส่ง',
    vehicleInformation: 'ข้อมูลพาหนะ',
    vehicleUsageData: 'ข้อมูลการใช้รถ',
    vehicleLists: 'รายการรถ',
    vehicleView: 'มุมมองพาหนะ',
    vehicleIsNotAvailable: 'พาหนะไม่พร้อมใช้งาน',
    vehiclePlateNo: 'ทะเบียนรถ',
    vehicleData: 'ข้อมูลรถ',
  },
  variable: {
    abnormal_behavior: 'พฤติกรรมการขับขี่ไม่ปลอดภัย',
    abnormal_temperature: 'อุณหภูมิผิดปกติ',
    boom: 'BOOM',
    compulsory_motor_insurance: 'ประกันภัยรถยนต์ภาคบังคับ',
    driving: 'ขับพาหนะ',
    driving_hour: 'ชั่วโมงการขับขี่',
    engine_oil: 'น้ำมันเครื่อง',
    event: 'เหตุการณ์',
    fuel: 'เชื้อเพลิง',
    high: 'สูง',
    insurance: 'ประกันภัย',
    invalidCredentials: 'ข้อมูลเซสชันไม่ถูกต้อง',
    invalidOldPassword: 'รหัสผ่านไม่ถูกต้อง',
    low: 'ต่ำ',
    maintenance_vehicle: 'พาหนะ',
    medium: 'กลาง',
    No: 'ไม่',
    no_entry: 'เข้าพื้นที่',
    no_exit: 'ออกพื้นที่',
    off: 'ปิด',
    on: 'เปิด',
    parking: 'จอดพาหนะ',
    parking_area: 'จุดจอด',
    prohibited_area: 'พื้นที่',
    pto_door: 'เปิด PTO/ประตู',
    safety: 'ความปลอดภัย',
    sensor: 'เซ็นเซอร์',
    sos: 'SOS',
    speed: 'ความเร็ว',
    speed_limit: 'ความเร็ว',
    speed_limit_area: 'ความเร็วในพื้นที่',
    stop: 'จอดรถทั่วไป',
    stop_engine_on: 'จอดรถติดเครื่องทั่วไป',
    stop_engine_on_long_time: 'จอดรถติดเครื่องนาน (ครั้้ง)',
    stop_engine_on_long_date: 'จอดรถติดเครื่องนาน (เวลา)',
    stop_engine_on_with_area: 'จอดรถติดเครื่องระบุพื้นที่',
    stop_with_area: 'จอดรถทั่วไประบุพื้นที่',
    stop_with_engine_off: 'จอดรถดับเครื่อง',
    stop_with_engine_off_long_time: 'จอดรถดับเครื่องนาน (ครั้ง)',
    success: 'สำเร็จ',
    suspension: 'ช่วงล่าง',
    taxi: 'Taxi',
    temperature: 'อุณภูมิ',
    timeline_aggressive_acceleration: 'ออกตัวกระชาก',
    timeline_aggressive_acceleration_time: 'ออกตัวกระชาก (ครั้ง)',
    timeline_speed_over: 'ความเร็วเกินกำหนด',
    timeline_suddenly_break: 'เบรคกะทันหัน',
    timeline_vehicle_stop_with_engine_on: 'จอดรถติดเครื่อง',
    tire: 'ยาง',
    voluntary_motor_insurance: 'ประกันภัยรถยนต์ภาคสมัครใจ',
    Yes: 'ใช่',
  },
  vehicleType: {
    backhoe: 'รถแบคโฮ',
    bulk_cargo: 'เรือสินค้าเทกอง',
    bus: 'รถเมล์',
    car_6wheelers_trailer: 'รถ 6 ล้อ เทรลเลอร์',
    caravan_10wheelers: 'รถพ่วง 10 ล้อ',
    cargo_ship: 'เรือ',
    cash_transporter_cmc01: 'รถขนส่งเงิน - CMC 01',
    cash_transporter_cmc02: 'รถขนส่งเงิน - CMC 02',
    cash_transporter_g45: 'รถขนส่งเงิน - G4S',
    cash_transporter_lrd130: 'รถขนส่งเงิน - LRD130',
    cash_transporter_test01: 'รถขนส่งเงิน - Test 01',
    cement_bags_transporter_10wheelers: 'รถขนส่งปูนถุง 10 ล้อ',
    cement_powder_banana_shaped_trailer: 'รถปูนผง กล้วยหอม',
    cement_powder_full_trailer: 'รถปูนผง พ่วงเต้า',
    concrete_pump: 'ปั๊มคอนกรีต',
    container_truck_10wheelers: 'รถตู้คอนเทนเนอร์ 10 ล้อ',
    container_truck_6wheelers: 'รถตู้คอนเทนเนอร์ 6 ล้อ',
    drive_10wheelers: 'รถ 10 ล้อ',
    drive_12wheelers: 'รถ 12 ล้อ',
    drive_18wheelers: 'รถ 18 ล้อ',
    drive_3wheelers: 'รถ 3 ล้อ',
    drive_6wheelers: 'รถ 6 ล้อ',
    dump_truck: 'รถดั๊ม',
    express_4wheelers: '4 ล้อ เอ็กเพรส',
    fjsldk: 'fjsldk',
    flatbed_car: 'รถพื้นเรียบ',
    forage_transporter_10wheelers: 'รถขนอาหารสัตว์ 10 ล้อ',
    forage_transporter_12wheelers: 'รถขนอาหารสัตว์ 12 ล้อ',
    forage_transporter_18wheelers: 'รถขนอาหารสัตว์ 18 ล้อ',
    forage_transporter_22wheelers: 'รถขนอาหารสัตว์ 22 ล้อ',
    forage_transporter_trailer: 'รถขนอาหารสัตว์ เทรลเลอร์',
    forklift: 'รถยก',
    freeze_truck_4wheelers: 'รถตู้เย็น 4 ล้อ',
    freeze_truck_6wheelers: 'รถตู้เย็น 6 ล้อ',
    harvestar: 'รถเก็บเกี่ยว',
    hazardous_materials_10wheelers: 'รถบรรทุกวัตถุอันตราย 10 ล้อ',
    hazardous_materials_trailer: 'รถบรรทุกวัตถุอันตราย เทรลเลอร์',
    high_4wheelers: '4 ล้อสูง',
    isuzu: 'อีซูซุ',
    jumbo_4wheelers: '4 ล้อจัมโบ้',
    mini_4wheelers: '4 ล้อ มินิ',
    mix_trailer: 'เทรเลอร์มิกซ์',
    motorcycle: 'รถจักรยานยนต์',
    ngv_10wheelers: 'NGV 10 ล้อ',
    pickup_4wheelers: 'รถกระบะ 4 ล้อ',
    pickup_truck_10wheelers: 'รถกระบะ 10 ล้อ',
    pickup_truck_12wheelers: 'รถกระบะ 12 ล้อ',
    pickup_truck_14wheelers: 'รถกระบะ 14 ล้อ',
    pickup_truck_4bigwheelers: 'รถกระบะ 4 ล้อใหญ่',
    pickup_truck_4wheelers: 'รถกระบะ 4 ล้อ',
    pickup_truck_4wheelers25: 'รถกระบะ 4 ล้อ(2.5)',
    pickup_truck_6wheelers: 'รถกระบะ 6 ล้อ',
    pre_cast: 'PreCast',
    pump: 'Pump',
    rmx_truck_10wheelers: 'รถบรรทุก RMX 10 ล้อ',
    rmx_truck_4wheelers: 'รถบรรทุก RMX 4 ล้อ',
    rmx_truck_6wheelers: 'รถบรรทุก RMX 6 ล้อ',
    sedan: 'รถเก๋ง',
    semi_trailer: 'รถกึ่งพ่วง',
    semi_trailer_18wheelers: 'กึ่งพ่วง 18 ล้อ',
    semi_trailer_22wheelers_less_than_6m: 'รถกึ่งพ่วง 22 ล้อ < 6 ม.',
    semi_trailer_22wheelers_less_than_7m: 'รถกึ่งพ่วง 22 ล้อ < 7 ม.',
    semi_trailer_22wheelers_less_than_8m: 'รถกึ่งพ่วง 22 ล้อ < 8 ม.',
    semi_trailer_22wheelers_over_8m: 'รถกึ่งพ่วง 22 ล้อ > 8 ม.',
    semi_trailer_24wheelers: 'กึ่งพ่วง 24 ล้อ',
    semi_trailer_20wheelers: 'กึ่งพ่วง 20 ล้อ',
    truck_4wheel: 'รถบรรทุก 4 ล้อ',
    truck_6wheel: 'รถบรรทุก 6 ล้อ',
    truck_10wheel: 'รถบรรทุก 10 ล้อ',
    truck_12wheel: 'รถบรรทุก 12 ล้อ',
    truck_14wheel: 'รถบรรทุก 14 ล้อ',
    tail: 'หาง',
    taxi: 'รถแท็กซี่',
    tractor: 'รถไถ',
    trailer_18wheelers: 'รถพ่วง 18 ล้อ',
    trailer_20wheelers: 'รถพ่วง 20 ล้อ',
    trailer_22wheelers: 'รถพ่วง 22 ล้อ',
    trailer_24wheelers: 'รถพ่วง 24 ล้อ',
    type_test: 'ทดสอบ',
    van: 'รถตู้',
    van_dont_send_department: 'รถตู้(ไม่ส่งกรม)',
    van_tpt: 'รถตู้ TPT',
    banana_shaped_trailer: 'รถกล้วยหอม',
    tao_pune_car: 'รถเต้า',
    tao_pune_trailer_car: 'รถเต้าพ่วง',
    cranes50: 'รถเครน (50)',
    hydraulicWheelCrane: 'รถปั้นจั่นไฮดรอลิคล้อยาง',
    crawlerWheelCranes: 'รถปั้นจั่นล้อตีนตะขาบ',
    craneBoat: 'เรือปั้นจั่น',
    bigTruck: 'รถบรรทุกใหญ่ 10 ล้อ (60)',
    togo: 'TOGO',
    boom_pump: 'ปั๊มบูม',
    concret_epump: 'ปั๊มลาก',
    easy_pump: 'อีซี่ปั๊ม',
  },
  w: {
    waiting_for_ticket: 'รอออกตั๋ว',
    finished_ticket: 'ออกตั๋วเสร็จ/รอเริ่มผลิต',
    arrive: 'ถึงหน่วยงาน',
    start_pouring: 'เริ่มเท',
    finished_pouring: 'เทเสร็จสิ้นแล้ว',
    carry_out_production: 'กำลังผลิต/รอออกจากแพลนต์',
    out_of_plant: 'กำลังเดินทางไปหน่วยงาน',
    waiting_for_the_next_job: 'รอการรับงานครั้งต่อไป (นาที)',
    work_order_number: 'หมายเลขใบสั่งงาน',
    working_day: 'จำนวนวันวิ่งงาน',
    working_days: 'ชั่วโมงการทำงานทั้งหมด',
    working_hour: 'ชั่วโมง GPS ใช้งานได้',
    wrongPoint: 'เข้าผิดจุด',
    wrongPointDetail:
      'กรณีที่รถเข้าผิดหน่วยงานหรือผ่านจุดที่ไม่ใช่ของตัวเองเป็นระยะเวลา 1 นาที',
    waitProduction: 'รถโม่จอดรอผลิต',
    wait: 'รอ',
    w_friday: 'ศุกร์',
    w_monday: 'จันทร์',
    w_saturday: 'เสาร์',
    w_sunday: 'อาทิตย์',
    w_thursday: 'พฤหัสบดี',
    w_tuesday: 'อังคาร',
    w_wednesday: 'พุธ',
    waitAssessment: 'รอประเมิน',
    waitForEmailChecking: 'รอการบืน Email',
    waitingConfirm: 'รอยืนยัน',
    waitingForDelivery: 'รอจัดส่ง',
    waitingForJob: 'รองาน',
    waitingForUnloading: 'รอเท',
    wall: 'ผนัง',
    warrantyEnd: 'สิ้นสุดการรับประกัน',
    week: 'สัปดาห์',
    weekly: 'รายสัปดาห์',
    weightIn: 'ชั่งเบา',
    weightInAt: 'เวลาขึ้นชั่งเบา',
    weightInStatus: 'ชั่งเบา',
    weightOut: 'ชั่งหนัก',
    weightOutAt: 'เวลาชั่งหนัก',
    weightOutStatus: 'ชั่งหนัก',
    weWillContactYouAtTheTelephoneNumberProvided:
      'เราจะติดต่อคุณตามหมายเลขโทรศัพท์ที่แจ้งไว้',
    wheel: 'จำนวนวงล้อ',
    whenEventOccurred: 'ลักษณะการแจ้งเตือน',
    whichMayHaveToContactTheAppointmentLocationAgain:
      'โดยอาจจะมีการติดต่อสถานที่นัดอีกครั้งได้',
    wigth: 'กว้าง',
    workNow: 'งานปัจจุบัน',
    working: 'เวลาวิ่ง',
    workingTime: 'เวลาวิ่งงาน',
    workingTime_dd_hr__mm: 'เวลาวิ่งงาน (วัน:ชม:นาที)',
    workingTimeActual_dd_hr__mm: 'เวลาวิ่งงานจริง (วัน:ชม:นาที)',
    workingTimeActual: 'เวลาวิ่งงานจริง',
    workOverTime: 'ปฏิบัติงานเกินช่วงเวลา',
    withinTimeLimit: 'จัดส่งตรงเวลา',
    weight: 'น้ำหนัก (ตัน)',
    wheretheQRCodeIsScanned: 'ตำเเหน่งสเเกน QR Code',
    weighingHistoryReport: 'ประวัติการชั่งน้ำหนัก',
    wouldYouLikeToRejectThisItem: 'คุณต้องการปฏิเสธรายการนี้หรือไม่',
    wouldYouLikeToApproveThisItem: 'คุณต้องการอนุมัติรายการนี้หรือไม่',
    weightGainType: 'ประเภทการรับค่าน้ำหนัก',
    weightMode: 'ประเภทการชั่งน้ำหนัก',
    weightSource: 'แหล่งที่มาของน้ำหนัก',
    weighingFormat: 'รูปเเบบการชั่งน้ำหนัก',
    weighingRandomConditions: 'เงื่อนไขการสุ่มชั่งน้ำหนัก',
    weighedAt: 'ชั่งน้ำหนักที่',
    website: 'เว็บไซต์',
    wouldYouLikeToCloseTheseItems: 'คุณต้องการปิดงานรายการเหล่านี้หรือไม่',
    weightAccordingToWorksheet: 'น้ำหนักตามใบงาน',
    weightInImgAt: 'เวลาถ่ายรูปชั่งเบา',
    weightOutImgAt: 'เวลาถ่ายรูปชั่งหนัก',
    wouldYouLikeToConfirmTheseBookings:
      'คุณต้องการยืนยันการจองรายการเหล่านี้หรือไม่',
    workSchedule: 'ปฏิทินงานกองรถ',
  },
  weight_uom: {
    g: 'กรัม',
    kg: 'กิโลกรัม',
    weight_uom: 'หน่วยของน้ำหนัก',
    weight_uom_require: 'กรุณาเลือกหน่วยของน้ำหนัก',
  },
  weight_mode: {
    in: 'ชั่งเบา',
    out: 'ชั่งหนัก',
    total: 'ชั่งสุทธิ',
  },
  x: {},
  y: {
    YourInformationHasBeenSentToTheCpacIsReady:
      'ข้อมูลของท่านถูกส่งไปยัง เจ้าหน้าที่ CPAC เรียบร้อย',
    YourInformationHasBeenSentToTheContractorIsReady:
      'ข้อมูลของท่านถูกส่งไปยังผู้รับเหมาเรียบร้อย',
    youStatusHasChanged: 'สถานะพาหนะได้ถูกปฏิเสธแล้ว',
    YouCanCheckTheAbnormalityOfTheOilOn:
      'ท่านสามารถตรวจสอบความผิดปกติของน้ำมันได้ที่หน้า',
    yourLocation: 'ตำแหน่งของคุณ',
    year: 'ปี',
    yearly: 'รายปี',
    yes: 'ใช่',
    you: 'คุณ',
    youCanOonlyUploadExcelFile: 'คุณสามารถอัปโหลดไฟล์ EXCEL ได้เท่านั้น',
    youCanOonlyUploadPdfFile: 'คุณสามารถอัปโหลดไฟล์ PDF ได้เท่านั้น',
    youCanOnlyUploadPNGorPDFfiles:
      'คุณสามารถอัปโหลดไฟล์ PNG, JPG, JPEG, PDF ได้เท่านั้น',
  },
  z: {
    zone: 'พื้นที่',
    zoneName: 'ชื่อพื้นที่',
    zoneNameIsRequired: 'กรุณากรอกชื่อพื้นที่',
    zoomIn: 'ขยายเข้า',
    zoomInForMoreInfo: 'ซูมเข้าเพื่อใช้งานฟีเจอร์',
    zoomOut: 'ขยายออก',
  },

  notifytype: {
    speed_over_limit: 'ความเร็วเกินกำหนด',
    driving_time_4_hrs: 'ขับต่อเนื่อง > 4 ชม.',
    working_time_10_hrs: 'ปฏิบัติงาน > 10 ชม. / วัน',
    aggressive_turn: 'เลี้ยวกระทันหัน',
    aggressive_acceleration_break: 'ออกตัวกระชาก/เบรคกระทันหัน',
    parking_on_road_side: 'จอดไหล่ทาง',
    no_parking_engine_on: 'จอดในที่ห้ามจอด(ไม่ดับเครื่อง)',
    no_parking_engine_off: 'จอดในที่ห้ามจอด(ดับเครื่อง)',
    unload_outside_location: 'เทนอกหน่วยงาน',
    delivery_over_3_hrs: 'จัดส่งนานเกิน 3 ชั่วโมง',
  },

  maintenance: {
    appointment_place: 'สถานที่นัด/แพลนต์',
    appointment_place_require: 'กรุณากรอกสถานที่นัด/แพลนต์',
    type_problem_require: 'กรุณาเลือกอาการปัญหา',
    comment_require: 'กรุณากรอกรายละเอียดเพิ่มเติม',

    province: 'จังหวัด',
    province_require: 'กรุณาเลือกจังหวัด',
    district_require: 'กรุณาเลือกเขต/อำเภอ',
    district: 'เขต/อำเภอ',
    vehicle_require: 'กรุณาเลือกรถ',
  },

  quantityUnit: {
    litre: 'ลิตร',
    tonne: 'ตัน',
    job: 'งาน',
    hour: 'ชั่วโมง',
    kg: 'กก.',
  },

  visibility: {
    vehicle: 'พาหนะ',
    vehicle_group: 'กลุ่มพาหนะ',
    location_group: 'กลุ่มสถานที่',
    location: 'สถานที่',
    user: 'ผู้ใช้งาน',
  },
  replayVehicleStatus: {
    จอดไม่ดับเครื่อง: 'จอดไม่ดับเครื่อง',
    วิ่ง: 'วิ่ง',
    คายคอนกรีต: 'คายคอนกรีต',
    ดับเครื่อง: 'ดับเครื่อง',
  },
  sdcardStatus: {
    normal: 'ปกติ',
    broken: 'เสีย',
    none: '-',
  },
  vehicleContract: {
    vehicleOwnerCompany: 'บริษัทเจ้าของรถ (เล่มรถ)',
    companyGroup: 'กลุ่มบริษัท (เรียกเก็บ)',
    phoneNumberSim: 'เบอร์โทร SIM',
    vehicleBrand: 'ยี่ห้อรถ',
    cuscNumber: 'เลขคัสซี',
    plateNo: 'ทะเบียน',
    province: 'จังหวัด',
    vehicleNumber: 'เบอร์รถจาก',
    vehicleNumberFilter: 'เบอร์รถ',
    gpsVendor: 'GPS Vendor',
    exPlateNo: 'ทะเบียนเก่า',
    contactInstall: 'ผู้ติดต่อติดตั้ง',
    phoneNumber: 'เบอร์ติดต่อ',
    installationLocation: 'สถานที่นัดติดตั้ง',
    installationDeviceType: 'ชนิดอุปกรณ์ที่ติดตั้ง',
    installDriversLicenseMachine: 'ติดเครื่องรูดใบขับขี่',
    attachSensor: 'ติดเซนเซอร์โม่',
    requestCertificate: 'ขอหนังสือรับรอง',
    otherDevice: 'อุปกรณ์อื่นๆ',
    installationDate: 'วันที่ติดตั้ง',
    expirationDate: 'วันที่หมดสัญญา',
    charge: 'เรียกเก็บ',
    installationStatus: 'สถานะการติดตั้ง',
    usageStatus: 'สถานะการใช้งาน',
    remark: 'หมายเหตุ',
    isBluenet: 'เป็นข้อมูลจาก Bluenet',
    expired: 'หมดอายุแล้ว',
    expiringOneMonth: 'กำลังจะหมดอายุ - 1 เดือน',
    expiringThreeMonth: 'กำลังจะหมดอายุ - 3 เดือน',
    default: 'ค่าเริ่มต้น',
    vehicleContract: 'สัญญาพาหนะ',
    vehicleNumberCpac: 'เบอร์รถ',
    index: 'เลขที่ Excel',
    errorResponse: 'ข้อความผิดพลาด',
    msg_install_status: 'สถานะการติดตั้งผิดพลาด',
    msg_is_install_drum_sensor: 'ติดตั้งเซนเซอร์โม่ผิดพลาด',
    msg_is_install_swipe_card: 'ติดเครื่องรูดใบขับขี่ผิดพลาด',
    msg_not_found_file: 'ไม่พบไฟล​์',
    msg_not_found_vehicle: 'ไม่พบพาหนะ',
    msg_working_status: 'สถานะการใช้งานผิดพลาด',
    msg_request_cert: 'ขอหนังสือรับรองผิดพลาด',
    templateReport: 'เทมเพลตรายงาน',
    import: 'นำเข้า',
    filter: 'ค้นหา',
    changeStatus: 'กดเพื่อเปลี่ยนสถานะ',
    editRemark: 'แก้ไขหมายเหตุ',
    vehicleContractDetail: 'รายละเอียด สัญญาพาหนะ',
    vehicleContractEdit: 'แก้ไข สัญญาพาหนะ',
    bath: '(บาท)',
    msg_vehicle_visibility:
      'ไม่มีสิทธิ์จัดการเนื่องจากเป็น GPS Vendor ไม่ตรงกัน',
  },
  vehicleVisibilityPermission: {
    generalData: 'สิทธิ์และสังกัดผู้ใช้งาน',
    vehicleVisibilityPermissionHeader: 'ตรวจสอบข้อมูลรถ',
    dataFrom: 'ข้อมูลจาก',
    default: 'ค่าเริ่มต้น',
    fleetlinkVehicleNumber: 'เบอร์รถ - Fleetlink',
    fleetlinkVehiclePlateNo: 'ทะเบียน - Fleetlink',
    terminusVehicleNumber: 'เบอร์รถ - TERMINUS',
    terminusVehiclePlateNo: 'ทะเบียน - TERMINUS',
    event: 'เหตุการณ์',
    searchBy: 'ค้นหาตาม',
    case1: 'มีเบอร์รถใน Fleetlink แต่ไม่มีใน TERMINUS',
    case2: 'มีเบอร์รถใน TERMINUS แต่ไม่มีใน Fleetlink',
    case3: 'มีเบอร์รถใน Fleetlink ซ้ำกัน',
    case4: 'มีทะเบียนรถใน Fleetlink ซ้ำกัน',
    case5:
      'เบอร์รถตรงกัน แต่ทะเบียนไม่เหมือนกัน ระหว่าง Fleetlink กับ TERMINUS',
    selectEvent: 'เลือกเหตุการณ์',
    vehicleVisibilityPermissionAndVehicleSharing: 'สิทธ์การมองเห็นพาหนะ',
    userAfiliation: 'สังกัดของผู้ใช้งาน',
    fillUser: 'กรุณากรอกผู้ใช้งาน',
    fillVehicleNumber: 'กรุณากรอกเบอร์รถ',
    dataCorrect: 'ข้อมูลตรง',
    dataIncorrect: 'ข้อมูลไม่ตรง',
  },
  vehicleSharingSystemAvailableAccount: {
    NOT_FOUND_VEHICLE: 'ไม่พบพาหนะ',
    NOT_FOUND_DEVICE_USER: 'ไม่พบคนขับ',
    PLEACE_CHECK_VEHICLE_STATUS_TO_INACTIVE_FIRST:
      'โปรดเปลี่ยนสถานะพาหนะเป็นไม่พร้อมก่อน',
    NOT_FOUND_TRUCK_SHARING_CURRENT_LOCATION:
      'ไม่พบตำแหน่งปัจจุบันของการแชร์รถบรรทุก',
    INVALID_TRUCK_SHARING_IS_AVALIABLE: 'มีปชร์รถบรรทุกที่ไม่ถูกต้อง',
    NOT_FOUND_TRUCK_SHARING_CONTACT_PHONE_OF_VEHICLE: 'ไม่พบเบอร์โทร',
    NOT_FOUND_MOBILE_PHONE_OF_DRIVERS: 'ไม่พบเบอร์โทร',
    NOT_FOUND_CONTACT_PHONE: 'ไม่พบเบอร์โทรผู้ประสานงาน',
    NOT_FOUND_DRIVER_MOBILE_PHONE: 'ไม่พบเบอร์โทรคนขับ',
    NOT_FOUND_TRUCK_SHARING_TYPE: 'กรุณากรอกประเภทพาหนะ',
  },
  listOfVehicleAccount: {
    START_DATE_CANNOT_BE_IN_THE_PAST: 'กรุณาเลือกเป็นวันปัจจุบัน',
  },
  month: {
    january: 'มกราคม',
    february: 'กุมภาพันธ์',
    march: 'มีนาคม',
    april: 'เมษายน',
    may: 'พฤษภาคม',
    june: 'มิถุนายน',
    july: 'กรกฎาคม',
    august: 'สิงหาคม',
    september: 'กันยายน',
    october: 'ตุลาคม',
    november: 'พฤศจิกายน',
    december: 'ธันวาคม',
  },
}

export { th }
